import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { CGenCiviliteRow } from '@way-lib-jaf/rowLoader';
import { WayBuild } from '@way-lib/common/decorator/WayBuild';

export class CGenPassagerRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_PASSAGER_ID%',
    class: 'Id',
  })
  public PAS_ID;

  @WayChamp({
    label  : '%LAB_GEN_PASSAGER_CIV_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Civilite',
  })
  public PAS_CIV_ID: CGenCiviliteRow;

  @WayChamp({
    label: '%LAB_GEN_PASSAGER_NOM%',
    class: 'Texte',
  })
  public PAS_NOM;

  @WayChamp({
    label: '%LAB_GEN_PASSAGER_PRENOM%',
    class: 'Texte',
  })
  public PAS_PRENOM;

  @WayChamp({
    label: '%LAB_GEN_PASSAGER_LAN_ID%',
    class: 'Liaison',
    // liaison: 'C_Cms_Langue',
  })
  public PAS_LAN_ID;

  @WayChamp({
    label: '%LAB_TELEPHONE_MOBILE1%',
    class: 'Telephone',
  })
  public PAS_TELEPHONE;

  @WayChamp({
    label: '%LAB_TELEPHONE_MOBILE2%',
    class: 'Telephone',
  })
  public PAS_TELEPHONE2;

  @WayChamp({
    label: '%LAB_TELEPHONE_FIXE1%',
    class: 'Telephone',
  })
  public PAS_TELEPHONE3;

  @WayChamp({
    label: '%LAB_TELEPHONE_FIXE2%',
    class: 'Telephone',
  })
  public PAS_TELEPHONE4;

  @WayChamp({
    label: '%LAB_GEN_PASSAGER_INFO_CHAUFFEUR%',
    class: 'Textarea',
  })
  public PAS_INFO_CHAUFFEUR;

  @WayChamp({
    label: '%LAB_GEN_PASSAGER_INFO_INTERNE%',
    class: 'Textarea',
  })
  public PAS_INFO_INTERNE;

  @WayChamp({
    label: '%LAB_GEN_PASSAGER_FLAG_SMS%',
    class: 'Flag',
  })
  public PAS_FLAG_SMS;

  @WayChamp({
    label: '%LAB_GEN_PASSAGER_FLAG_TPMR%',
    class: 'Flag',
  })
  public PAS_FLAG_TPMR;

  @WayChamp({
    label: '%LAB_EMAIL%',
    class: 'Email',
  })
  public PAS_EMAIL;

  @WayBuild(['PAS_ID'])
  build_etapes() {
    this.concept.cm.getConcept('C_Gen_Presence').all.forEach((prs) => {
      if (prs.PRS_PAS_ID === this && typeof prs.PRS_MIS_ID === 'object')
        prs.PRS_MIS_ID.launchBuildByRow(['build_etapes']);
    });
  }
}
