import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector   : 'way-search',
  templateUrl: './way-search.component.html',
  styleUrls  : ['./way-search.component.scss'],
})
export class WaySearchComponent {
  @Input() placeholder: string;

  @Input() searchValue: string;

  @Output() searchValueChange = new EventEmitter<string>();

  onChange(value) {
    this.searchValueChange.emit(value);
  }
}
