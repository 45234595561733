import { CComServiceRow } from '@way-lib-jaf/rowLoader';
import { JafConcept } from '@way-lib-jaf/concept';
import { Jaf } from '@way-lib-jaf/jaf';

export class CComService extends JafConcept {
  public primary = 'SER_ID';

  protected name = 'nf_com_service';

  protected class = 'C_Com_Service';

  protected rowClass = 'CComServiceRow';

  protected trigramme = 'SER';

  getLibelle(serviceId: string): string {
    const services = <CComServiceRow[]>this.all;
    const libelle  = services.find(
      (service: CComServiceRow) => service.SER_CAT_ID.CAT_ID === serviceId,
    )?.SER_LIBELLE;
    return Jaf.translate(libelle);
  }
}
