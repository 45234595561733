import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignupPage } from './signup.page';

const routes: Routes = [
  {
    path     : '',
    component: SignupPage,
    children : [
      {
        path        : 'profile-form',
        loadChildren: () =>
          import('../profile/profile-form/profile-form.module').then((m) => m.ProfileFormModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignupPageRoutingModule {}
