import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonMaskDirective } from './ion-mask.directive';

@NgModule({
  declarations: [IonMaskDirective],
  imports     : [CommonModule],
  exports     : [IonMaskDirective],
})
export class DirectivesModule {}
