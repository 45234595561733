import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsEquipementRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_EQUIPEMENT_ID%',
    class: 'Id',
  })
  public EQU_ID;

  @WayChamp({
    label: '%LAB_GDS_EQUIPEMENT_LIBELLE%',
    class: 'Libelle',
  })
  public EQU_LIBELLE;

  @WayChamp({
    label: '%LAB_GDS_EQUIPEMENT_TRI%',
    class: 'Tri',
  })
  public EQU_TRI;
}