import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadZero',
})
export class LeadZeroPipe implements PipeTransform {
  transform(num: number, zeroCount: number): string {
    const n        = Math.abs(num);
    const zeros    = Math.max(0, zeroCount - Math.floor(n).toString().length);
    let zeroString = (10 ** zeros).toString().substr(1);

    if (num < 0) {
      zeroString = `-${zeroString}`;
    }

    return zeroString + n;
  }
}
