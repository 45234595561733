import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { JafRow } from '@way-lib-jaf/row';

export class CGdsDemandeDtrRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_DDT_ID%',
    class: 'Id',
  })
  public DDT_ID;

  @WayChamp({
    label: '%LAB_GDS_DDT_DTR_ID%',
    class: 'Liaison',
  })
  public DDT_DTR_ID;

  @WayChamp({
    label: '%LAB_GDS_DDT_INS_ID%',
    class: 'Liaison',
  })
  public DDT_INS_ID;

  @WayChamp({
    label: '%LAB_GDS_DDT_DATE_SEND%',
    class: 'Datetime',
  })
  public DDT_DATE_SEND;

  @WayChamp({
    label: '%LAB_GDS_DDT_DATE_CONFIRM%',
    class: 'Datetime',
  })
  public DDT_DATE_CONFIRM;

  @WayChamp({
    label: '%LAB_GDS_DDT_DATE_REFUSE%',
    class: 'Datetime',
  })
  public DDT_DATE_REFUSE;
}
