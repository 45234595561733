import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsCercle extends JafConcept {
  public primary = 'CER_ID';

  protected name = 'nf_gds_cercle';

  protected class = 'C_Gds_Cercle';

  protected rowClass = 'CGdsCercleRow';

  protected trigramme = 'CER';

}
