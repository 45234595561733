// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageErreur {
  padding: 2em;
  text-align: center;
  background-color: #FFD0D0;
}`, "",{"version":3,"sources":["webpack://./projects/way-d/src/app/pages/auth/profile/profile-form/profile-form.component.scss"],"names":[],"mappings":"AAAA;EAAiB,YAAA;EAAa,kBAAA;EAAoB,yBAAA;AAIlD","sourcesContent":[".messageErreur { padding:2em; text-align: center; background-color: #FFD0D0;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
