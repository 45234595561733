import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenEntiteCommercialeRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_ID%',
    class: 'Id',
  })
  public ECO_ID;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_LIBELLE%',
    class: 'Libelle',
  })
  public ECO_LIBELLE;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_IMAT_REGISTRE%',
    class: 'Texte',
  })
  public ECO_IMAT_REGISTRE;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_TITRE_FICHE_MISSION%',
    class: 'Texte',
  })
  public ECO_TITRE_FICHE_MISSION;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_LOGO%',
    class: 'Image',
  })
  public ECO_LOGO;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_COORDONNEE_HAUT%',
    class: 'Textarea',
  })
  public ECO_COORDONNEE_HAUT;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_COORDONNEE_BAS%',
    class: 'Textarea',
  })
  public ECO_COORDONNEE_BAS;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_RIB%',
    class: 'Textarea',
  })
  public ECO_RIB;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_CONDITION_REGLEMENT%',
    class: 'Textarea',
  })
  public ECO_CONDITION_REGLEMENT;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_ENTETE_FICHE_MISSION%',
    class: 'Textarea',
  })
  public ECO_ENTETE_FICHE_MISSION;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_CGV%',
    class: 'Textarea',
  })
  public ECO_CGV;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_PAR_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_Partenaire',
  })
  public ECO_PAR_ID;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_URL%',
    class: 'Url',
  })
  public ECO_URL;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_COPYRIGHT%',
    class: 'Textarea',
  })
  public ECO_COPYRIGHT;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_FLAG_MARQUE_BLANCHE%',
    class: 'Flag',
  })
  public ECO_FLAG_MARQUE_BLANCHE;

  @WayChamp({
    label: '%LAB_GEN_ENTITECOMMERCIALE_CODE_COMPTA%',
    class: 'Texte',
  })
  public ECO_CODE_COMPTA;

  @WayChamp({
    label: '%LAB_GEN_ECO_SIRET%',
    class: 'Texte',
  })
  public ECO_SIRET;

  @WayChamp({
    label: '%LAB_GEN_CHORUS_ID%',
    class: 'Texte',
  })
  public ECO_CHORUS_ID;
}
