import { JafConcept } from '@way-lib-jaf/concept';
import { CGenCategorieChauffeurRow } from '@way-lib-jaf/rowLoader'

export class CGenCategorieChauffeur extends JafConcept {
  public primary = 'CCH_ID';

  protected name = 'nf_gen_categorieChauffeur';

  protected class = 'C_Gen_CategorieChauffeur';

  protected rowClass = 'CGenCategorieChauffeurRow';

  protected trigramme = 'CCH';

  _languages: Array<CGenCategorieChauffeurRow>

  get languages(): Array<CGenCategorieChauffeurRow> {
    if(this._languages === undefined || this._languages.length === 0){
      this.build_languages()
    }
    return this._languages
  }

  build_languages() {
    this._languages = new Array<CGenCategorieChauffeurRow>()
    this._languages = this.cm.getConcept('C_Gen_CategorieChauffeur').all.filter((category) => {
      return category.CCH_CCH_ID?.CCH_ID === CGenCategorieChauffeurRow.CCH_LANGUAGES
    })
  }

}
