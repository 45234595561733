import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { WayMode, WayModeService } from '../../services/way-mode.service';

export interface TabsMenuItem {
  label: string;
  route: Array<string>;
  icon: string;
  class?: string;
  liste?: Array<any>;
}

@Component({
  selector   : 'way-tabs',
  templateUrl: './way-tabs.component.html',
  styleUrls  : ['./way-tabs.component.scss'],
})
export class WayTabsComponent implements OnInit {
  @Input() items: Array<TabsMenuItem>;

  private subscribeOk = false;

  showTabs(): boolean {
    if (WayMode.WAY_PARTNER === this.wayModeService.getMode()) {
      return this.subscribeOk;
    }
    return true;
  }

  constructor(
    public subscriptionService: SubscriptionsService,
    public wayModeService: WayModeService,
  ) {}

  ngOnInit() {
    if (WayMode.WAY_PARTNER === this.wayModeService.getMode()) {
      this.subscriptionService.subscriptions$.subscribe(subscription => {
        this.subscribeOk = this.subscriptionService.hasActiveSubscription(subscription);
      });
    }
  }
}
