import { JafConcept } from '@way-lib-jaf/concept';

export class CComIban extends JafConcept {
  public primary = 'IBA_ID';

  protected name = 'nf_com_iban';

  protected class = 'C_Com_Iban';

  protected rowClass = 'CComIbanRow';

  protected trigramme = 'IBA';
}
