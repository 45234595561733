import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsPartenaireCercle extends JafConcept {
  public primary = 'PAC_ID';

  protected name = 'nf_gds_partenaireCercle';

  protected class = 'C_Gds_PartenaireCercle';

  protected rowClass = 'CGdsPartenaireCercleRow';

  protected trigramme = 'PAC';

}
