import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth-routing.module';
import { SigninPageModule } from './signin/signin.module';
import { ForgotPasswordPageModule } from './forgot-password/forgot-password.module';
import { SignupPageModule } from './signup/signup.module';
import { ProfilePageModule } from './profile/profile.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AuthRoutingModule,
    SigninPageModule,
    SignupPageModule,
    ForgotPasswordPageModule,
    ProfilePageModule,
  ],
})
export class AuthModule {}
