import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsNiveauPrestige extends JafConcept {
  public primary = 'NPR_ID';

  protected name = 'nf_gds_niveauPrestige';

  protected class = 'C_Gds_NiveauPrestige';

  protected rowClass = 'CGdsNiveauPrestigeRow';

  protected trigramme = 'NPR';

  public _flagUniqueRowset = true;
}