import { Inject, Injectable } from '@angular/core'

// eslint-disable-next-line no-use-before-define
export enum WayMode {
  WAY_PARTNER = 'WAY_PARTNER',
  WAY_D = 'WAY_D',
  BOTH = 'BOTH'
}

@Injectable({
  providedIn: 'root',
})
export class WayModeService {

  private static _instance: WayModeService;

  private _mode: WayMode = WayMode.WAY_PARTNER

  constructor(@Inject('WaySuffix') public suffix: string){
    if(suffix === 'D'){
      this.setMode(WayMode.WAY_D)
    }
    WayModeService._instance = this;
  }

  setMode(mode: WayMode) {
    this._mode = mode
  }

  getMode() {
    return this._mode
  }

  static RestrictTo(allowedMode: WayMode) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      const originalMethod = descriptor.value;
      // eslint-disable-next-line consistent-return
      descriptor.value = function (...args: any[]) {
        const currentMode = WayModeService._instance.getMode();
        if (currentMode === allowedMode || allowedMode === WayMode.BOTH) {
          return originalMethod.apply(this, args);
        } 
          console.error(`Method ${propertyKey} can only be called in ${allowedMode} mode. Current mode: ${currentMode}`);
        
      };
      return descriptor;
    };
  }
}
