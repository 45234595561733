import { Component, Input } from '@angular/core';

@Component({
  selector   : 'loader',
  templateUrl: './loader.component.html',
  styleUrls  : ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() width: string = '54px';
 
  @Input() height: string = '54px'; 
}
