import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsDemandeDtr extends JafConcept {
  public primary = 'DDT_ID';

  protected name = 'nf_gds_demandeDtr';

  protected class = 'C_Gds_DemandeDtr';

  protected rowClass = 'CGdsDemandeDtrRow';

  protected trigramme = 'DDT';
}
