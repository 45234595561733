import { Component, Input } from '@angular/core';

@Component({
  selector   : 'way-main-title',
  templateUrl: './way-main-title.component.html',
  styleUrls  : ['./way-main-title.component.scss'],
})
export class WayMainTitleComponent {
  @Input() title = 'Define Title';

  @Input() icon: string;

  @Input() description: string;

  showHelp: boolean = false;

  constructor() {
    this.icon = 'ri-question-line';
  }

  toggleHelp() {
    this.showHelp = !this.showHelp;
  }
}
