import { JafConcept } from '@way-lib-jaf/concept';

export class CGenTypeVehicule extends JafConcept {
  public primary = 'TVE_ID';

  protected name = 'nf_gen_typeVehicule';

  protected class = 'C_Gen_TypeVehicule';

  protected rowClass = 'CGenTypeVehiculeRow';

  protected trigramme = 'TVE';
}
