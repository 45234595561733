import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@way-lib/common/services/auth/auth.service';

@Component({
  selector   : 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls  : ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage {
  submitting = false;

  fpForm = new UntypedFormGroup({
    login: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  constructor(private authService: AuthService, private router: Router) {}

  onSubmit() {
    if (!this.fpForm.valid) {
      return;
    }

    this.submitting = true;

    this.authService.requestPasswordEmail(this.fpForm.value.login).subscribe({
      next: () => {
        this.router.navigate(['/', 'auth', 'signin']);
      },
      error: () => {
        this.fpForm.setErrors({ badrequest: true });
        this.submitting = false;
      },
    });
  }

  showErrors(control: string) {
    const { dirty, touched, errors } = this.fpForm.get(control);
    return dirty && touched && errors;
  }
}
