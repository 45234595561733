import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsPaysRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_PAYS_ID%',
    class: 'Id',
  })
  public PAY_ID;

  @WayChamp({
    label: '%LAB_GDS_PAYS_LIBELLE%',
    class: 'Libelle',
  })
  public PAY_LIBELLE;

  @WayChamp({
    label: '%LAB_GDS_PAYS_CODE_CHIFFRE%',
    class: 'Quantite',
  })
  public PAY_CODE_CHIFFRE;

  @WayChamp({
    label: '%LAB_GDS_PAYS_CODE_ISO2%',
    class: 'Texte',
  })
  public PAY_CODE_ISO2;

  @WayChamp({
    label: '%LAB_GDS_PAYS_CODE_ISO3%',
    class: 'Texte',
  })
  public PAY_CODE_ISO3;

  @WayChamp({
    label: '%LAB_GDS_PAYS_CURRENCY_SYMBOL%',
    class: 'Texte',
  })
  public PAY_CURRENCY_SYMBOL;

  @WayChamp({
    label: '%LAB_GDS_PAYS_CURRENCY_LETTER%',
    class: 'Texte',
  })
  public PAY_CURRENCY_LETTER;

  @WayChamp({
    label: '%LAB_GDS_PAYS_ETAT%',
    class: 'Etat',
  })
  public PAY_ETAT;
}
