import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
  transform(url: string): string {
    let tmpUrl = url;
    tmpUrl     = tmpUrl.substr(tmpUrl.indexOf('file=') + 1);
    tmpUrl     = tmpUrl.substr(tmpUrl.indexOf('-') + 1);
    return tmpUrl;
  }
}
