import { CGenClientRow, CGenContactRow, CComFactureRow, CGenMissionRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { WayChampBuild } from '@way-lib/common/decorator/WayChampBuild';

export class CComCommandeRow extends JafRow {
  static SCO_IN_PROGRESS = '1';

  static SCO_SENT_TO_SUBCONTRACTOR = '2';

  static SCO_QUOTE_OK = '3';

  static SCO_QUOTE_CONFIRMED = '4';

  static SCO_INVOICE_OK = '5';

  static SCO_INVOICE_PAID = '7';

  static SCO_CANCELLED = '6';

  static SCO_MODIFING = '9';

  static COM_FLAG_HT = '1';

  static COM_FLAG_TTC = '0';

  @WayChamp({
    label: '%LAB_COM_COMMANDE_ID%',
    class: 'Id',
  })
  public COM_ID;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_DATE_CREATION%',
    class: 'Datetime',
  })
  public COM_DATE_CREATION;

  @WayChamp({
    label  : '%LAB_COM_COMMANDE_SCO_ID%',
    class  : 'Liaison',
    // liaison: 'C_Com_StatutCommande',
    indexed: true,
  })
  public COM_SCO_ID;

  @WayChamp({
    label  : '%LAB_COM_COMMANDE_CLI_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Client',
    indexed: true,
  })
  public COM_CLI_ID: CGenClientRow;

  @WayChamp({
    label  : '%LAB_COM_COMMANDE_COT_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Contact',
  })
  public COM_COT_ID: CGenContactRow;

  @WayChamp({
    label  : '%LAB_COM_COMMANDE_FAC_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_Facture',
    indexed: true,
  })
  public COM_FAC_ID: CComFactureRow;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_COM_DEMANDE%',
    class: 'Textarea',
  })
  public COM_DEMANDE;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_COMMENTAIRE_INTERNE%',
    class: 'Textarea',
  })
  public COM_COMMENTAIRE_INTERNE;

  @WayChamp({
    label: '%LAB_COM_CODE_EVENEMENT%',
    class: 'Texte',
  })
  public COM_CODE_EVENEMENT;

  @WayChamp({
    label  : '%LAB_COM_COMMANDE_GRI_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_Grille',
  })
  public COM_GRI_ID;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_DATE_CONFIRMATION%',
    class: 'Date',
  })
  public COM_DATE_CONFIRMATION;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_COL_ID%',
    class: 'Liaison',
    // liaison: 'C_Soc_Collaborateur',
  })
  public COM_COL_ID;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_MDE_ID%',
    class: 'Liaison',
    // liaison: 'C_Com_ModeleDevis',
  })
  public COM_MDE_ID;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_CONDITION%',
    class: 'Textarea',
  })
  public COM_CONDITION;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_PAR_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_Partenaire',
  })
  public COM_PAR_ID;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_TCO_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_TypeCondtions',
  })
  public COM_TCO_ID;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_TDO_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_TypeDossier',
  })
  public COM_TDO_ID;

  @WayChamp({
    label  : '%LAB_COM_COMMANDE_COM_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_Commande',
  })
  public COM_COM_ID;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_FLAG_CGV_FACTURE%',
    class: 'Flag',
  })
  public COM_FLAG_CGV_FACTURE;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_FLAG_HT%',
    class: 'Flag',
  })
  public COM_FLAG_HT;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_COMMISSION_MONTANT%',
    class: 'Montant',
  })
  public COM_COMMISSION_MONTANT;

  @WayChamp({
    label: '%LAB_COM_COMMANDE_DATE_DERNIERE_MODIFICATION%',
    class: 'Datetime',
  })
  public COM_DATE_DERNIERE_MODIFICATION;

  @WayChampBuild('Index', {
    conceptName: 'C_Gen_Mission',
    indexName  : 'MIS_COM_ID',
    tri        : 'MIS_NUMERO',
  })
  public miss: Array<CGenMissionRow>;

  @WayChampBuild()
  public totalTtc: number;

  build_totalTtc() {
    this.totalTtc = 0;
    this.miss.forEach((mis) => {
      mis.fmis.forEach((fmi) => {
        this.totalTtc +=
          Math.round(
            fmi.FMI_VENTE_HT *
              fmi.FMI_QTE *
              (1 - fmi.FMI_POURCENTAGE_REMISE / 100) *
              (1 + fmi.FMI_TVA / 100) *
              100,
          ) / 100;
      });
    });
    return this.totalTtc;
  }

  changeStatus(status: string) {
    this.setterSynchro('COM_SCO_ID', status);
  }

}
