import { Component, Input } from '@angular/core';

@Component({
  selector   : 'way-sub-title',
  templateUrl: './way-sub-title.component.html',
  styleUrls  : ['./way-sub-title.component.scss'],
})
export class WaySubTitleComponent {
  @Input() title = 'Define Title';
}
