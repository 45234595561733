import { JafConcept } from '@way-lib-jaf/concept';

export class CComModeReglement extends JafConcept {
  public primary = 'MRE_ID';

  protected name = 'nf_com_modeReglement';

  protected class = 'C_Com_ModeReglement';

  protected rowClass = 'CComModeReglementRow';

  protected trigramme = 'MRE';
}
