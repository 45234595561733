import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsTypeVehiculeRow extends JafRow {
  static TVH_SEDAN_BERLINE = '8';

  static TVH_VAN = '3';

  static TVH_BUS = '9';

  static TVH_MOTO = '7';

  static TVH_LIMOUSINE = '13';
  
  static TVH_MINIBUS = '15';
  
  static TVH_MONOSPACE = '16';
  
  static TVH_BREAK = '17';
  
  static TVH_COUPE = '18';
  
  static TVH_CABRIOLET = '19';
  
  static TVH_COMPACTE = '20';
  
  static TVH_4X4 = '21';
  
  static TVH_CITADINE = '22';
  
  static TVH_SUV = '12';

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_ID%',
    class: 'Id',
  })
  public TVH_ID;

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_LIBELLE%',
    class: 'Libelle',
  })
  public TVH_LIBELLE;

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_DESCRIPTION%',
    class: 'Textarea',
  })
  public TVH_DESCRIPTION;

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_CAPACITE%',
    class: 'Quantite',
  })
  public TVH_CAPACITE;

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_TRI%',
    class: 'Id',
  })
  public TVH_TRI;
}
