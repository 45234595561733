import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector   : 'way-input-code',
  templateUrl: './way-input-code.component.html',
  styleUrls  : ['./way-input-code.component.scss'],
})
export class WayInputCodeComponent {
  @Input() phoneCode = '';

  @Output() phoneCodeChange = new EventEmitter<string>();

  onPhoneCodeChange = () => {
    if(this.phoneCode){
      this.phoneCodeChange.emit(this.phoneCode.toString());
    }
  };
}
