import { JafConcept } from '@way-lib-jaf/concept';

export class CGenContact extends JafConcept {
  public primary = 'COT_ID';

  protected name = 'nf_gen_contact';

  protected class = 'C_Gen_Contact';

  protected rowClass = 'CGenContactRow';

  protected trigramme = 'COT';
}
