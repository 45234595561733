import { CGenMissionRow, CGenPassagerRow, CGenEtapePresenceRow, CGdsChampMissionRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { WayBuild } from '@way-lib/common/decorator/WayBuild';
import { WayChampBuild } from '@way-lib/common/decorator/WayChampBuild';
import { MissionCMI } from '../../mission/interfaces/mission';

export class CGenPresenceRow extends JafRow {
  static PRS_PREVU = '1';

  static PRS_PRESENT = '2';

  static PRS_ABSENT = '3';

  static PRS_NO_SHOW = '4';

  @WayChamp({
    label : '%LAB_GEN_PRESENCE_ID%',
    class : 'Id',
    builds: ['build_etapes'],
  })
  public PRS_ID;

  @WayChamp({
    label  : '%LAB_GEN_PRESENCE_MIS_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Mission',
    indexed: true,
  })
  public PRS_MIS_ID: CGenMissionRow;

  @WayChamp({
    label  : '%LAB_GEN_PRESENCE_PAS_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Passager',
    builds : ['build_etapes'],
  })
  public PRS_PAS_ID: CGenPassagerRow;

  @WayChamp({
    label : '%LAB_GEN_PRESENCE_TRI%',
    class : 'Tri',
    builds: ['build_etapes'],
  })
  public PRS_TRI;

  @WayChamp({
    label : '%LAB_GEN_PRESENCE_TPP_ID%',
    class : 'Quantite',
    builds: ['build_etapes'],
  })
  public PRS_TPP_ID;

  @WayChamp({
    label  : '%LAB_GEN_PRESENCE_PC_EPR_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_EtapePresence',
    builds : ['build_etapes'],
  })
  public PRS_PC_EPR_ID: CGenEtapePresenceRow | null;

  @WayChamp({
    label  : '%LAB_GEN_PRESENCE_DE_EPR_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_EtapePresence',
    builds : ['build_etapes'],
  })
  public PRS_DE_EPR_ID: CGenEtapePresenceRow | null;

  @WayChamp({
    label  : '%LAB_GEN_PRESENCE_PRS_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Presence',
  })
  public PRS_PRS_ID: CGenPresenceRow;

  @WayChamp({
    label : '%LAB_GEN_PRESENCE_BAGAGE%',
    class : 'Quantite',
    builds: ['build_etapes'],
  })
  public PRS_BAGAGE;

  @WayChamp({
    label : '%LAB_GEN_PRESENCE_CMI%',
    class : 'Texte',
    builds: ['build_etapes'],
  })
  public PRS_CMI: string;

  public dropOff = { label: 'Société Waynium' };

  /** TODO: */
  private _CMIS: MissionCMI;

  get CMIS() {
    if (!this._CMIS) {
      this.build_CMIS();
    }
    return this._CMIS;
  }

  @WayChampBuild()
  public champsMission: Array<CGdsChampMissionRow>;

  @WayBuild(['PRS_CMI'])
  build_CMIS() {
    if (this._CMIS === undefined) {
      this._CMIS = {};
    }
    this._CMIS.passengers = this.getSynthese('PASSAGER');
    this._CMIS.baggage    = this.getSynthese('BAGAGE');
    this._CMIS.equipment  = this.getSynthese('EQUIPEMENT');
  }

  build_champsMission() {
    this.champsMission = this.concept.cm.getConcept('C_Gds_ChampMission').getIndex('CMI_ID');
  }

  @WayBuild(['PRS_ID'])
  build_etapes() {
    // console.log(`je lance build_etapes sur PRS_ID=${this.PRS_ID}`);
    if (typeof this.PRS_MIS_ID === 'object')
      this.PRS_MIS_ID.launchBuildByRow(['build_prss', 'build_etapes']);
  }

  get isPresenceModifiable(): boolean {
    const statutsCompatibles = [
      CGenMissionRow.SMI_EN_PLACE,
      CGenMissionRow.SMI_MISSION_TERMINER,
      CGenMissionRow.SMI_TRAITEMENT_RETOUR,
      CGenMissionRow.SMI_VERIFICATION,
    ];
    return (
      this.PRS_TPP_ID === CGenPresenceRow.PRS_PREVU &&
      statutsCompatibles.indexOf(this.PRS_MIS_ID.MIS_SMI_ID) > -1
    );
  }

  setPresence() {
    this.setterSynchro('PRS_TPP_ID', CGenPresenceRow.PRS_PRESENT);
  }

  setNoShow() {
    this.setterSynchro('PRS_TPP_ID', CGenPresenceRow.PRS_NO_SHOW);
    // on vérifie que tous les passagers sont en no show pour declarer la mission no show
    let flag = true;
    if (typeof this.PRS_MIS_ID === 'object') {
      this.PRS_MIS_ID.prss.forEach((prs) => {
        flag = flag && prs.PRS_TPP_ID === CGenPresenceRow.PRS_NO_SHOW;
      });
      if (flag) {
        this.PRS_MIS_ID.setFlagNoShow();
      }
    }
  }

  getSynthese(CMI_GROUPE) {
    const count: {
      [key: string]: {
        label: string;
        count: number;
      };
    } = {};
    const CMISJson = JSON.parse(this.PRS_CMI);
    if (CMISJson) {
      this.champsMission.forEach((row) => {
        if (row.CMI_GROUPE === CMI_GROUPE) {
          if (CMISJson[row.CMI_ID] !== undefined && CMISJson[row.CMI_ID]) {
            const nb = +CMISJson[row.CMI_ID];

            if (nb > 0) {
              if (undefined === count[row.CMI_ID]) {
                count[row.CMI_ID] = {
                  label: `DIC_WAYD_${row.CMI_LABEL}`,
                  count: nb,
                };
              } else {
                count[row.CMI_ID].count += nb;
              }
            }
          }
        }
      });
    }

    const res  = Object.values(count);
    const list = this.sortRowEquipement(res);

    let total = 0;
    list.forEach((equipement) => {
      total += equipement.count;
    });
    return { list, total };
  }

  sortRowEquipement(data) {
    data.sort((a, b) => {
      if (a.label === b.label) return 0;
      return a.label > b.label ? 1 : -1;
    });
    return data;
  }
}
