import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenTypeRegleAgendaRow extends JafRow {
  static TYE_AVAILABLE = '1';

  static TYE_UNAVAILABLE = '3';

  @WayChamp({
    label: 'Id',
    class: 'Id',
  })
  public TYE_ID;

  @WayChamp({
    label: 'libelle',
    class: 'Texte',
  })
  public TYE_LIBELLE;

  @WayChamp({
    label: 'bouton present',
    class: 'Flag',
  })
  public TYE_FLAG_WAYD;
}
