import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';

import { Controller } from '@way-lib-jaf/controller';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { Gds } from '@way-lib-jaf/gds';
import { GeolocationService } from '@way-lib/common/services/geolocation.service';
import { CreateApiResponse } from '@way-lib/common/interfaces/loginCredential';
import { NotificationService } from '@way-lib/common/services/notification.service';
import { AuthService } from '@way-lib/common/services/auth/auth.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { App, AppInfo } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { LangService } from '@way-lib/ui/lang.service'
import pack from '../../package.json';
import { MenuService } from './services/menu.service';
import { environment } from '../environments/environment';

@Component({
  selector       : 'app-root',
  templateUrl    : 'app.component.html',
  styleUrls      : ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends Controller implements OnInit {

  name    = pack.name;

  version = pack.version;

  labelVersion: string = 'version';

  gdsName: string = environment.name;

  protected actionLoadData: string = '/gdsv3/get-data-chauffeur';

  relanceLoadenCours = false;

  public disableMenu = true;

  private apiRootUrl = environment.apiRootUrl;

  constructor(
    private platform: Platform,
    private notificationService: NotificationService,
    private http: HttpClient,
    private storage: Storage,
    private geolocation: GeolocationService,
    protected cm: ConceptManager,
    // protected logger: NGXLogger,
    protected gds: Gds,
    private changeDetectorRef: ChangeDetectorRef,
    protected translate: TranslateService,
    public menuService: MenuService,
    public authService: AuthService,
    public langService: LangService,
    @Inject('WaySuffix') public suffix: string
  ) {
    setTimeout(()=>{
      this.labelVersion =  this.translate.instant('version');
      this.cm.detectChanges();
    },500);
    super(cm, gds, translate, authService, langService);
  }

  updateLoggerParams(achId = null) {
    const httpParams = new HttpParams().set('appName', this.name).set('appRelease', this.version);
    if (achId) {
      httpParams.set('achId', achId);
    }
  }

  initializeApp() {
    if (this.platform.is('capacitor')) {
      SplashScreen.hide();
    }
    this.initLanguages();
    this.cm.setDectectorRef(this.changeDetectorRef);
    this.cm.setOnConnexion(() => {
      this.onConnexion();
    });
  }

  onConnexion() {
    this.disableMenu = false;
    this.status      = 4;
    this.run();
  }

  createApi() {
    this.http.get<CreateApiResponse>(`${this.apiRootUrl.divers}/wayd/create-api`).subscribe(
      (res: CreateApiResponse) => {
        if (res.error) {
          console.warn('Create API failed', res.error);
          setTimeout(() => {
            this.initApi();
          }, 3000);
        }
        const { ACH_ID, ACH_SECRET_KEY } = res.params;
        this.storage.set('wayd.ach_id', ACH_ID);
        this.storage.set('wayd.ach_secret_key', ACH_SECRET_KEY);
        this.gds.achId        = ACH_ID;
        this.gds.achSecretKey = ACH_SECRET_KEY;
        this.updateLoggerParams(this.gds.achId);
        super.initApi();
      },
      (error) => {
        console.warn('Create API failed', error);
        setTimeout(() => {
          this.initApi();
        }, 3000);
      },
    );
  }

  initApi() {
    Promise.all([this.storage.get('wayd.ach_id'), this.storage.get('wayd.ach_secret_key')]).then(
      (values) => {
        const [achId, achSecretKey] = values;
        if (achId && achSecretKey) {
          this.gds.achId        = achId;
          this.gds.achSecretKey = achSecretKey;
          this.updateLoggerParams(this.gds.achId);
          super.initApi();
        } else {
          this.createApi();
        }
      },
    );
  }

  start() {
    if (!this.started) {
      this.notificationService.init();
    }
    super.start();
  }

  initConnexion() {
    this.cm
      .init()
      .then((connected) => {
        if (connected) {
          this.disableMenu = false;
          Device.getInfo().then((deviceInfo:DeviceInfo) => {
            this.gds.post('divers', `/gdsv3/device-info`, deviceInfo);
          });
          super.initConnexion();
          if (this.geolocation.enabled) {
            this.geolocation.requestGeolocationStart();
          }
        }
      })
      .catch(console.error);
  }

  async ngOnInit() {
    await this.storage.create();
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        App.getInfo().then((appInfo:AppInfo) => {
          if (appInfo.name) {
            this.name = appInfo.name;
          }
          if (appInfo.version) {
            this.version = appInfo.version;
          }
        });
      } else {
        this.name    = pack.name;
        this.version = pack.version;
      }

      this.initializeApp();
      this.updateLoggerParams();

      this.cm.configConcept = [
        'C_Gds_ChampMission',
        'C_Gds_EvenementChauffeur',
        'C_Gen_Civilite',
        'C_Com_ModeReglement',
        'C_Gen_EntiteCommerciale',
        'C_Com_Grille',
        'C_Gen_Chauffeur',
        'C_Gen_Client',
        'C_Gen_Passager',
        'C_Geo_Lieu',
        'C_Com_TypeService',
        'C_Gen_TypeVehicule',
        'C_Gen_Voiture',
        'C_Gen_Contact',
        'C_Com_Facture',
        'C_Com_Commande',
        'C_Gen_Mission',
        'C_Com_Reglement',
        'C_Gen_EtapePresence',
        'C_Gen_Presence',
        'C_Gen_AgendaChauffeur',
        'C_Gen_TypeRegleAgenda',
        'C_Gen_PlageInterditeIndispo'
      ];
      this.cm.actionSynchro = '/gdsv3/synchronize-chauffeur';
      this.init();
    });
  }
}
