import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { IonInput } from '@ionic/angular';

import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import IMask from 'imask';

interface IonMaskOptions {
  mask: string;
}

@Directive({
  selector : '[appIonMask]',
  providers: [IonInput],
})
export class IonMaskDirective implements OnInit, OnDestroy {
  @Input('appIonMask') mask = '';

  private onDestroy: Subject<void> = new Subject<void>();

  private maskedInput: IMask.InputMask<IonMaskOptions>;

  constructor(private ionInput: IonInput) {}

  public ngOnInit() {
    this.configure();
  }

  public ngOnDestroy() {
    this.onDestroy.next();
    this.maskedInput.destroy();
  }

  private async configure() {
    const input: HTMLElement          = await this.ionInput.getInputElement();
    const maskOptions: IonMaskOptions = {
      mask: this.mask,
    };

    this.maskedInput = IMask(input, maskOptions);

    this.ionInput.ionChange
      .pipe(
        takeUntil(this.onDestroy),
        map((event) => {
          const { value }             = event.detail;
          this.maskedInput.typedValue = value;

          return this.maskedInput.value;
        }),
      )
      .subscribe((maskedValue) => {
        this.ionInput.value = maskedValue;
      });
  }
}
