import { JafConcept } from '@way-lib-jaf/concept';

export class CGeoLieu extends JafConcept {
  public primary = 'LIE_ID';

  protected name = 'nf_geo_lieu';

  protected class = 'C_Geo_Lieu';

  protected rowClass = 'CGeoLieuRow';

  protected trigramme = 'LIE';
}
