import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CComGrilleRow extends JafRow {
  @WayChamp({
    label: '%LAB_COM_GRILLE_ID%',
    class: 'Id',
  })
  public GRI_ID;

  @WayChamp({
    label: '%LAB_COM_GRILLE_LIBELLE%',
    class: 'Libelle',
  })
  public GRI_LIBELLE;

  @WayChamp({
    label  : '%LAB_COM_GRILLE_ECO_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_EntiteCommerciale',
  })
  public GRI_ECO_ID;

  @WayChamp({
    label: '%LAB_COM_GRILLE_SEQ_ID%',
    class: 'Liaison',
    // liaison: 'C_Soc_Sequence',
  })
  public GRI_SEQ_ID;

  @WayChamp({
    label: '%LAB_COM_GRILLE_AVOIR_SEQ_ID%',
    class: 'Liaison',
    // liaison: 'C_Soc_Sequence',
  })
  public GRI_AVOIR_SEQ_ID;

  @WayChamp({
    label: '%LAB_COM_GRILLE_TRI%',
    class: 'Tri',
  })
  public GRI_TRI;

  @WayChamp({
    label: '%LAB_COM_GRILLE_DEFAUT%',
    class: 'Flag',
  })
  public GRI_DEFAUT;

  @WayChamp({
    label: '%LAB_COM_GRILLE_FLAG_FORCER_DESCRIPTION%',
    class: 'Flag',
  })
  public GRI_FLAG_FORCER;

  @WayChamp({
    label: '%LAB_COM_GRILLE_ETAT%',
    class: 'Etat',
  })
  public GRI_ETAT;
}
