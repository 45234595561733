import { Component, Input, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { IonSegment } from '@ionic/angular';
import {  Router , NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs'


export interface SegmentNavItem {
  count?: number | Array<any>;
  index: string;
  route: Array<string>;
  label: string;
}

@Component({
  selector   : 'way-segment-nav',
  templateUrl: './way-segment-nav.component.html',
  styleUrls  : ['./way-segment-nav.component.scss'],
})
export class WaySegmentNavComponent implements AfterViewInit, OnDestroy {
  @ViewChild(IonSegment) segment: IonSegment;

  @Input() items: Array<SegmentNavItem>;

  segmentValue = '';

  private subscriptions: Subscription[] = [];

  constructor(private router: Router) {}

  ngAfterViewInit() {
    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.setActive();
      })
    )
  }

  count(val: Array<any> | number): number {
    if (typeof val === 'number') {
      return val;
    }
    return val.length;
  }

  private setActive() {
    if (!this.items || !this.segment) return;
  
    const activeItem = this.items.find((item) => {
      const itemRoute = item.route.join('/');
      return this.router.url === itemRoute || this.router.url.includes(itemRoute);
    });
  
    const [firstItem] = this.items;
    this.segmentValue = activeItem === undefined ? firstItem.index : activeItem.index;
  
    this.segment.value = this.segmentValue;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
