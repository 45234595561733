import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenCategorieChauffeurRow extends JafRow {

  static CCH_LANGUAGES = '1';

  static CCH_SPECIALTIES = '2';

  static CCH_DRIVING = '10';

  static CCH_CONTRACT = '13';

  static CCH_FRENCH = "4";

  static CCH_ENGLISH = "3"


  @WayChamp({
    label: '%LAB_GEN_CATEGORIECHAUFFEUR_ID%',
    class: 'Id',
  })
  public CCH_ID;

  @WayChamp({
    label  : '%LAB_GEN_CATEGORIECHAUFFEUR_CCH_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_CategorieChauffeur',
  })
  public CCH_CCH_ID: CGenCategorieChauffeurRow;

  @WayChamp({
    label: '%LAB_GEN_CATEGORIECHAUFFEUR_LIBELLE%',
    class: 'Libelle',
  })
  public CCH_LIBELLE;

  @WayChamp({
    label: '%LAB_GEN_CATEGORIECHAUFFEUR_TRI%',
    class: 'Tri',
  })
  public CCH_TRI;

  @WayChamp({
    label: '%LAB_GEN_CATEGORIECHAUFFEUR_ICONE%',
    class: 'Texte',
  })
  public CCH_ICONE;

}
