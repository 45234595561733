import { JafConcept } from '@way-lib-jaf/concept';

export class CComReglement extends JafConcept {
  public primary = 'REL_ID';

  protected name = 'nf_com_reglement';

  protected class = 'C_Com_Reglement';

  protected rowClass = 'CComReglementRow';

  protected trigramme = 'REL';
}
