import { Component } from '@angular/core';

@Component({
  selector   : '@way-lib-way-tooltip',
  templateUrl: './way-tooltip.component.html',
  styleUrls  : ['./way-tooltip.component.scss'],
})
export class WayTooltipComponent {
  tooltip: string = '';

  left: number = 0;

  top: number = 0;
}
