import { Inject, Injectable } from '@angular/core';
import { KJUR } from 'jsrsasign';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
// import { NGXLogger } from 'ngx-logger';
import { Storage } from '@ionic/storage-angular';
import { Observable } from 'rxjs';

export interface GdsResponse {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class Gds {
  public achId: number;

  public achSecretKey: string;

  private debug = false;

  private chauffeur;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    // private logger: NGXLogger,
    @Inject('apiRootUrl') private apiRootUrl,
  ) {}

  setChauffeur(chauffeur) {
    this.chauffeur = chauffeur;
  }

  makeApiToken(payload: object) {
    const headers = {
      ach_id: this.achId,
      time  : Math.round(new Date().getTime() / 1000),
    };

    if (!this.achSecretKey) {
      console.error('achSecretKey !!!!!!!', this.achSecretKey);
    }
    const token = KJUR.jws.JWS.sign('HS256', headers, payload, this.achSecretKey);
    return token;
  }

  getParamsTokenVide() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const time    = Math.round(new Date().getTime() / 1000);
    const headers = {
      ach_id: this.achId,
      time,
    };

    if (!this.achSecretKey) {
      return { token: 'pas de ach_id' };
    }
    const token = KJUR.jws.JWS.sign('HS256', headers, { nop: '' }, this.achSecretKey);
    return {
      time,
      ach_id: this.achId,
      token,
    };
  }

  getUrlRequest(zoneDomaine: string, action: string): string {

    if ( this.chauffeur ) {
      let  url = 'https://gds.waynium.net';
      switch (zoneDomaine) {
        case "geolocalisation"    : url = this.chauffeur.CHU_GDS_URL_GEOLOC; break;
        case "evenementChauffeur" : url = this.chauffeur.CHU_GDS_URL_EVC;    break;
        case "divers"             : url = this.chauffeur.CHU_GDS_URL_DIVERS; break;
        case "flight"             : url = this.apiRootUrl[zoneDomaine]; break;
        case "timePrediction"     : url = this.apiRootUrl[zoneDomaine]; break;
        default: url = 'https://gds.waynium.net';
      }
      return `${url}${action}`

    }


    return `${this.apiRootUrl[zoneDomaine]}${action}`;
  }

  /**
   * @deprecated The method should not be used
   * Prefer to use `post` and `get` than return Observable
   */
  sendV2(
    zoneDomaine: string,
    url: string,
    payload: object,
    callback?,
    errorCallback?,
  ): Observable<GdsResponse> {

    const request = this.post(zoneDomaine, `${url}`, payload);

    request.subscribe(
      (res) => {
        // console.log(`receive de /gdsv3/${url}`, res);
        if (callback) callback(res);
      },
      (res: HttpErrorResponse) => {
        /**
         * si il ya une erreur avec l'ACH_ID (changement de server gds)
         */
        if (res.status === 401) {
          console.error('il y a une erreur 401. Attention remettre le storage.clear() apres debug');
          // this.storage.clear();
         // window.location.reload();
        }
        // console.log(`not receive de ${url}`, res);
        console.error('API Call: failed', { url, payload, res });
        if (errorCallback) errorCallback(res);
      },
    );

    return request;
  }

  post(zoneDomaine: string, url: string, payload?: object): Observable<GdsResponse> {
    const token      = this.makeApiToken(payload);
    const requestUrl = this.getUrlRequest(zoneDomaine, url);

    return this.http.post(requestUrl, { token });
  }

  get(zoneDomaine: string, url: string, payload?: object, params?: HttpParams) {
    const token      = this.makeApiToken(payload);
    const requestUrl = this.getUrlRequest(zoneDomaine, url);

    const httpHeaders = new HttpHeaders().append('Authorization', `Bearer ${token}`);

    const options = { headers: httpHeaders, params };

    return this.http.get(requestUrl, options);
  }

  getPdf(zoneDomaine: string, url: string, payload: object): Observable<GdsResponse> {
    const token      = this.makeApiToken(payload);
    const requestUrl = this.getUrlRequest(zoneDomaine, url);

    const httpHeaders = new HttpHeaders().set('Accept', 'application/pdf');

    return this.http.post(requestUrl, { token }, { headers: httpHeaders, responseType: 'blob' });
  }
}
