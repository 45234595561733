import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TooltipDirective } from './tooltip/tooltip.directive.component'
import { WayTooltipComponent } from './way-tooltip.component'

@NgModule({
  declarations: [WayTooltipComponent, TooltipDirective],
  imports     : [CommonModule],
  exports     : [TooltipDirective],
})
export class TooltipModule {}
