export function WayBuild(lc: Array<string>) {
  return (target: any, key: string) => {
    if (target.listeChampMakeBuild === undefined) {
      // eslint-disable-next-line no-param-reassign
      target.listeChampMakeBuild = {};
    }

    lc.forEach((nc) => {
      if (!target.listeChampMakeBuild[nc]) {
        // eslint-disable-next-line no-param-reassign
        target.listeChampMakeBuild[nc] = [key];
      } else if (!target.listeChampMakeBuild[nc].includes(key)) {
        target.listeChampMakeBuild[nc].push(key);
      }
    });
  };
}
