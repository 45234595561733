import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsTveSelect extends JafConcept {
  public primary = 'TVS_ID';

  protected name = 'nf_gds_tveselect';

  protected class = 'C_Gds_TveSelect';

  protected rowClass = 'CGdsTveSelectRow';

  protected trigramme = 'TVS';
}
