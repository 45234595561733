import { Component, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector   : 'way-copy',
  templateUrl: './copy.component.html',
  styleUrls  : ['./copy.component.scss'],
})
export class CopyComponent {
  @Input() contentToCopy: string;

  constructor(private toastController: ToastController, private translate: TranslateService) {}

  public copyToClipboard(): void {
    navigator.clipboard.writeText(this.contentToCopy);
    this.toastController
      .create({
        position   : 'top',
        translucent: false,
        message    : this.translate.instant('Copié dans le presse-papier'),
        duration   : 2000,
        color      : 'success',
      })
      .then((toast) => toast.present());
  }
}
