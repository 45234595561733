import { Component, Input } from '@angular/core';

@Component({
  selector   : 'arrow-move-left',
  templateUrl: './arrow-move-left.component.html',
  styleUrls  : ['./arrow-move-left.component.scss'],
})
export class ArrowMoveLeftComponent {

    @Input() fill: string;

    @Input() width: string;

}
