import { JafConcept } from '@way-lib-jaf/concept';

export class CGenPresence extends JafConcept {
  public primary = 'PRS_ID';

  protected name = 'nf_gen_presence';

  protected class = 'C_Gen_Presence';

  protected rowClass = 'CGenPresenceRow';

  protected trigramme = 'PRS';
}
