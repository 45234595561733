import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsTveSelectRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_TVESELECT_ID%',
    class: 'Id',
  })
  public TVS_ID;

  @WayChamp({
    label: '%LAB_GDS_LABEL_ID%',
    class: 'Libelle',
  })
  public TVS_LIBELLE;

  @WayChamp({
    label: '%LAB_GDS_DESCRIPTION%',
    class: 'Textarea',
  })
  public TVS_DESCRIPTION;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_INS_ID%',
    class: 'Liaison',
  })
  public TVS_INS_ID;

  @WayChamp({
    label: '%LAB_GDS_TVESELECT_TRI%',
    class: 'Id',
  })
  public TVS_TRI;
}
