import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenCiviliteRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_CIVILITE_ID%',
    class: 'Id',
  })
  public CIV_ID;

  @WayChamp({
    label: '%LAB_GEN_CIVILITE_LIBELLE_COURT%',
    class: 'Texte',
  })
  public CIV_LIBELLE_COURT;

  @WayChamp({
    label: '%LAB_GEN_CIVILITE_LIBELLE_LONG%',
    class: 'Texte',
  })
  public CIV_LIBELLE_LONG;
}
