import { Component, Input, OnInit, Inject } from '@angular/core';
import { SubscriptionsService } from '@way-lib/common/services/subscriptions.service';
import { WayModeService } from '@way-lib/common/services/way-mode.service';

enum TabAction {
  TOGGLE_MENU = 'TOGGLE_MENU',
}

interface TabsMenuItem {
  label?: string;
  route?: Array<string>;
  icon: string;
  iconSize?: string;
  class?: string;
  color?: string;
  list?: Array<any>;
  action?: TabAction;
}

@Component({
  selector   : 'way-partner-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls  : ['./nav-tabs.component.scss'],
})
export class NavTabsComponent implements OnInit {
  @Input() onlyToolbar: boolean = true;

  public tabItems: Array<TabsMenuItem>;

  public hasSubscription = false;

  public TabAction = TabAction;

  constructor(
    private subscriptionService: SubscriptionsService,
    public wayModeService: WayModeService,
    @Inject('menuService') private menuService
  ) {}

  ngOnInit() {
    this.tabItems = this.menuService.tabs;
    this.subscriptionService.subscriptions$.subscribe((subscription) => {
      this.hasSubscription = this.subscriptionService.hasActiveSubscription(subscription);
    });
  }
}
