import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsEnergieRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_ENERGIE_ID%',
    class: 'Id',
  })
  public ENE_ID;

  @WayChamp({
    label: '%LAB_GDS_ENERGIE_LIBELLE%',
    class: 'Libelle',
  })
  public ENE_LIBELLE;

}