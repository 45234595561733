import { JafConcept } from '@way-lib-jaf/concept';

export class CGenCivilite extends JafConcept {
  public primary = 'CIV_ID';

  protected name = 'nf_gen_civilite';

  protected class = 'C_Gen_Civilite';

  protected rowClass = 'CGenCiviliteRow';

  protected trigramme = 'CIV';
}
