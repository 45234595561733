import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsChampMission extends JafConcept {
  public primary = 'CMI_ID';

  protected name = 'nf_gds_champMission';

  protected class = 'C_Gds_ChampMission';

  protected rowClass = 'CGdsChampMissionRow';

  protected trigramme = 'CMI';

  public _flagUniqueRowset = true;
}
