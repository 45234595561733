import { JafConcept } from '@way-lib-jaf/concept';

export class CGenVoiture extends JafConcept {
  public primary = 'VOI_ID';

  protected name = 'nf_gen_voiture';

  protected class = 'C_Gen_Voiture';

  protected rowClass = 'CGenVoitureRow';

  protected trigramme = 'VOI';
}
