import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsLangueChauffeursRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_LANGUECHAUFFEURS_ID%',
    class: 'Id',
  })
  public LCU_ID;

  @WayChamp({
    label: '%LAB_GDS_LAG_ID%',
    class: 'Liaison',
  })
  public LCU_LAG_ID;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_INS_ID%',
    class: 'Liaison',
  })
  public LCU_INS_ID;
}
