import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { NavigationEnd, Router } from '@angular/router';
import { ConceptManager } from '../../services/jaf/concept-manager';
import { CGdsInstallationRow } from '../../services/jaf/rowLoader';

@Component({
  selector   : 'way-menu',
  templateUrl: './way-menu.component.html',
  styleUrls  : ['./way-menu.component.scss'],
})
export class WayMenuComponent implements OnInit, OnDestroy {
  @Input() disabled: any;

  @Input() suffix: any;

  @Input() menu: any;

  @Input() name: any;

  @Input() gdsName: any;

  @Input() labelVersion: any;

  @Input() version: any;

  installation: CGdsInstallationRow;

  installationCode: string;

  routeSubscription: Subscription;

  disabledMenu: boolean = false;

  constructor(
    private cm: ConceptManager,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.cm.setOnLoad(() => {
      this.installation     = this.cm.getInstallationPrincipal();
      this.installationCode = this.installation ? this.installation.getInstallationCode() : '';
    });
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.disabledMenu = event.url === '/signup' || event.url === '/signin';
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }
}
