import { CComCommandeRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { WayChampBuild } from '@way-lib/common/decorator/WayChampBuild';

export class CComFactureRow extends JafRow {
  @WayChamp({
    label: '%LAB_COM_FACTURE_ID%',
    class: 'Id',
  })
  public FAC_ID;

  @WayChamp({
    label  : '%LAB_COM_FACTURE_ECO_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_EntiteCommerciale',
  })
  public FAC_ECO_ID;

  @WayChamp({
    label  : '%LAB_COM_FACTURE_CLI_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Client',
  })
  public FAC_CLI_ID;

  @WayChamp({
    label: '%LAB_COM_FACTURE_DATE%',
    class: 'Date',
  })
  public FAC_DATE;

  @WayChamp({
    label: '%LAB_COM_FACTURE_ADRESSE%',
    class: 'Texte',
  })
  public FAC_ADRESSE;

  @WayChamp({
    label: '%LAB_COM_FACTURE_ADRESSE_2%',
    class: 'Texte',
  })
  public FAC_ADRESSE_2;

  @WayChamp({
    label: '%LAB_COM_FACTURE_ADRESSE_3%',
    class: 'Texte',
  })
  public FAC_ADRESSE_3;

  @WayChamp({
    label: '%LAB_COM_FACTURE_CP%',
    class: 'Codepostal',
  })
  public FAC_CP;

  @WayChamp({
    label: '%FAC_VILLE%',
    class: 'Ville',
  })
  public FAC_VILLE;

  @WayChamp({
    label: '%LAB_COM_FACTURE_PAY_ID%',
    class: 'Liaison',
    // liaison: 'C_Geo_Pays',
  })
  public FAC_PAY_ID;

  @WayChamp({
    label: '%LAB_COM_FACTURE_COMMENTAIRE%',
    class: 'Textarea',
  })
  public FAC_COMMENTAIRE;

  @WayChamp({
    label: '%LAB_COM_FACTURE_DATE_REGLEMENT_ATTENDUE%',
    class: 'Date',
  })
  public FAC_DATE_REGLEMENT_ATTENDUE;

  @WayChamp({
    label: '%LAB_COM_FACTURE_MONTANT_ECHEANCE%',
    class: 'Montant',
  })
  public FAC_MONTANT_ECHEANCE;

  @WayChamp({
    label: '%LAB_COM_FACTURE_DATE_REGLEMENT%',
    class: 'Date',
  })
  public FAC_DATE_REGLEMENT;

  @WayChamp({
    label: '%LAB_COM_FACTURE_FLAG_EXPORT%',
    class: 'Flag',
  })
  public FAC_FLAG_EXPORT;

  @WayChamp({
    label: '%LAB_COM_FACTURE_MFA_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_ModeleFacture',
  })
  public FAC_MFA_ID;

  @WayChamp({
    label: '%LAB_COM_FACTURE_CONDITION_REGLEMENT%',
    class: 'Textarea',
  })
  public FAC_CONDITION_REGLEMENT;

  @WayChamp({
    label: '%LAB_COM_FACTURE_MONTANT_DEBOURS%',
    class: 'Montant',
  })
  public FAC_MONTANT_DEBOURS;

  @WayChamp({
    label  : '%LAB_COM_FACTURE_FAC_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_Facture',
  })
  public FAC_FAC_ID;

  @WayChamp({
    label: '%LAB_GEN_CHORUS_ID%',
    class: 'Texte',
  })
  public FAC_CHORUS_ID;

  @WayChamp({
    label: '%LAB_COM_FACTURE_CFS_ID%',
    class: 'Liaison',
    // liaison: 'C_Soc_Sequence',
  })
  public FAC_CFS_ID;

  @WayChamp({
    label: '%LAB_COM_FACTURE_CHORUS_NUMERO%',
    class: 'Liaison',
    // liaison: 'C_Gen_ChorusFactureStatut',
  })
  public FAC_CHORUS_NUM;

  @WayChamp({
    label: '%LAB_COM_FACTURE_NOM%',
    class: 'Texte',
  })
  public FAC_NOM;

  @WayChamp({
    label: '%LAB_COM_FACTURE_NUMERO%',
    class: 'Texte',
  })
  public FAC_NUMERO;

  @WayChamp({
    label: '%LAB_COM_FACTURE_FLAG_REGLE%',
    class: 'Flag',
  })
  public FAC_FLAG_REGLE;

  @WayChamp({
    label: '%LAB_COM_FACTURE_MONTANT_REGLER%',
    class: 'Montant',
  })
  public FAC_MONTANT_REGLER;

  @WayChamp({
    label: '%LAB_COM_FACTURE_MONTANT_TTC%',
    class: 'Montant',
  })
  public FAC_MONTANT_TTC;

  @WayChampBuild('Index', {
    conceptName: 'C_Com_Commande',
    indexName  : 'COM_FAC_ID',
    tri        : 'COM_ID',
  })
  public coms: Array<CComCommandeRow>;

  get isPaid(): boolean {
    if (this.FAC_DATE_REGLEMENT) {
      return true;
    }
    return this.coms.every((com) => com.COM_SCO_ID === CComCommandeRow.SCO_INVOICE_PAID);
  }
}
