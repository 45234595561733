import { JafConcept } from '@way-lib-jaf/concept';

export class CGenOptionclient extends JafConcept {
  public primary = 'OPI_ID';

  protected name = 'nf_gen_optionclient';

  protected class = 'C_Gen_Optionclient';

  protected rowClass = 'CGenOptionclientRow';

  protected trigramme = 'OPI';
}
