import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsLangueChauffeurs extends JafConcept {
  public primary = 'LCU_ID';

  protected name = 'nf_gds_langueChauffeurs';

  protected class = 'C_Gds_LangueChauffeurs';

  protected rowClass = 'CGdsLangueChauffeursRow';

  protected trigramme = 'LCU';
}
