import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { AuthService } from '@way-lib/common/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public tabs = [
    {
      icon : 'ri-list-check',
      route: ['/missions', 'list', 'inprocess'],
      label: this.translate.instant('Missions à venir'),
    },
    {
      icon : 'ri-file-download-line',
      route: ['/missions', 'list', 'tbc'],
      label: this.translate.instant('Missions à confirmer'),
      liste: this.cm.getConcept('C_Gen_Mission').tbc,
    },
    {
      icon : 'ri-home-fill',
      route: ['/dashboard'],
      class: 'home-btn',
      label: this.translate.instant('Home'),
    },
    {
      icon : 'ri-file-upload-line',
      route: ['/missions', 'list', 'toclose'],
      label: this.translate.instant('Missions à clôturer'),
      liste: this.cm.getConcept('C_Gen_Mission').toclose,
    },
    {
      icon : 'ri-map-pin-line',
      route: ['/geolocation'],
      // class: this.geolocation.enabled ? 'success' : 'danger',
      label: this.translate.instant('Geolocation'),
    },
  ];

  public main = [
    [
      {
        title: 'Home',
        url  : ['/dashboard'],
        icon : 'ri-home-line',
      },
      {
        title: 'List of assignments',
        url  : ['/missions'],
        icon : 'ri-list-check',
      },
      {
        title: 'Daily calendar',
        url  : ['/calendar'],
        icon : 'ri-calendar-event-line',
      },
    ],
    [
      {
        title: 'My profile',
        url  : ['/auth', 'profile', 'profile-form'],
        icon : 'ri-user-line',
      },
      {
        title: 'Availablity',
        url  : ['/agenda'],
        icon : 'ri-calendar-check-fill',
      },
      {
        title: 'Geolocation',
        url  : ['/geolocation'],
        icon : 'ri-map-pin-line',
      },
      {
        title: 'Way-d code',
        url  : ['/code'],
        icon : 'ri-key-2-line',
      },
    ],
    [
      {
        title: 'FAQ',
        url  : ['/faq'],
        icon : 'ri-question-line',
      },
      {
        title: 'Politique de confidentialité',
        url  : ['/cgu'],
        icon : 'ri-question-line',
      },
      {
        title: 'Test',
        url  : ['/test'],
        icon : 'ri-question-line',
      },
    ],
    [
      {
        title: 'Update data',
        click: () => this.cm.resync(),
        icon : 'ri-refresh-line',
      },
      {
        title: 'Logout',
        click: () => this.authService.logout(),
        icon : 'ri-shut-down-line',
      },
    ],
  ];

  public detailMission = [
    {
      value: 'detailAnchor',
      label: this.translate.instant('Détail'),
    },
    {
      value: 'infosAnchor',
      label: this.translate.instant('Informations'),
    },
  ];

  constructor(
    private translate: TranslateService,
    private cm: ConceptManager,
    private authService: AuthService,
  ) {}
}
