import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector   : 'remix-icon',
  templateUrl: './remix-icon.component.html',
  styleUrls  : ['./remix-icon.component.scss'],
})
export class RemixIconComponent implements OnInit, OnChanges {
  @Input() icon: string;

  @Input() color: string;

  xlinkHref: string;

  ngOnInit() {
    this.xlinkHref = `/assets/icons/remix/remixicon.symbol.svg#${this.icon}`;
  }

  ngOnChanges() {
    this.xlinkHref = `/assets/icons/remix/remixicon.symbol.svg#${this.icon}`;
  }
}
