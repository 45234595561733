import { JafConcept } from '@way-lib-jaf/concept';
import { CGenTypeRegleAgendaRow } from '@way-lib-jaf/rowLoader';

export class CGenTypeRegleAgenda extends JafConcept {
  public primary = 'TYE_ID';

  protected name = 'nf_gen_typeRegleAgenda';

  protected class = 'C_Gen_TypeRegleAgenda';

  protected rowClass = 'CGenTypeRegleAgendaRow';

  protected trigramme = 'TYE';

  private _list: Array<CGenTypeRegleAgendaRow>;

  get list() {
    if (this._list === undefined) {
      this.build_list();
    }
    return this._list;
  }

  build_list() {
    if (this._list === undefined) {
      this._list = new Array<CGenTypeRegleAgendaRow>();
    }
    const list = new Map<String,CGenTypeRegleAgendaRow>();
    this.all.forEach((row:CGenTypeRegleAgendaRow)=>{
      if (!list.has(row.TYE_ID)) {
        list.set(row.TYE_ID,row);
      }
    });
    this._list.splice(0,this._list.length);
    list.forEach((row:CGenTypeRegleAgendaRow)=>{
      this._list.push(row);
    })
  }
}