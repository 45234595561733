import { JafConcept } from '@way-lib-jaf/concept';

export class CComCategorie extends JafConcept {
  public primary = 'CAT_ID';

  protected name = 'nf_com_categorie';

  protected class = 'C_Com_Categorie';

  protected rowClass = 'CComCategorieRow';

  protected trigramme = 'CAT';

}
