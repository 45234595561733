import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector   : 'way-number-input',
  templateUrl: './way-number-input.component.html',
  styleUrls  : ['./way-number-input.component.scss'],
})
export class WayNumberInputComponent {
  @Output() valueChange = new EventEmitter<number>();

  @Input() formControlName: string;

  @Input() value? = 0;

  @Input() title: string;

  increment() {
    this.value += 1;
    this.onChange();
  }

  decrement() {
    if (this.value > 0) {
      this.value -= 1;
      this.onChange();
    }
  }

  onChange() {
    this.valueChange.emit(this.value);
  }
}
