import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { take, switchMap, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { SubscriptionsService } from '../subscriptions.service';
import { WayMode, WayModeService } from '../way-mode.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private subscriptionsService: SubscriptionsService,
    private wayModeService: WayModeService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return from(this.authService.isAuthenticated()).pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          return this.router.navigate(['/auth']).then(() => this.router.createUrlTree(['/auth']));
        }

        if (this.wayModeService.getMode() === WayMode.WAY_PARTNER && !next.data.onlyAuthCheck) {
          return this.subscriptionsService.fetchSubscriptions().pipe(
            take(1),
            switchMap(() => this.subscriptionsService.hasActiveSubscription$),
            map((hasActiveSubscription) => {
              if (!hasActiveSubscription) {
                return this.router.createUrlTree(['/parameters']);
              }
              return true;
            }),
          );
        }
        return [true];
      }),
      map((result) => {
        if (result instanceof UrlTree) {
          return result;
        }
        return !!result;
      }),
    );
  }
}
