import { Component, Input } from '@angular/core';

type NavigationItem = {
  title: string;
  url?: any[] | string | null | undefined;
  click?: any;
  icon: string;
  liste?: any;
};

@Component({
  selector   : 'way-navigation',
  templateUrl: './way-navigation.component.html',
  styleUrls  : ['./way-navigation.component.scss'],
})
export class WayNavigationComponent {
  @Input() items: NavigationItem[][];
}
