import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WayLibModule } from '@way-lib/way-lib.module';
import { ProfilePageRoutingModule } from './profile-routing.module';
import { ProfilePage } from './profile.page';
import { ProfileFormModule } from './profile-form/profile-form.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ProfileFormModule,
    ProfilePageRoutingModule,
    WayLibModule,
  ],
  declarations: [ProfilePage],
})
export class ProfilePageModule {}
