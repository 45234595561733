import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector   : 'way-input',
  templateUrl: './way-input.component.html',
  styleUrls  : ['./way-input.component.scss'],
})
export class WayInputComponent implements ControlValueAccessor {
  @Input() disabled: boolean;

  @Input() label: string;

  @Input() info: string;

  @Input() error: string;

  @Input() id: string;

  @Input() maxLength: number;

  @Input() placeholder = '';

  @Input() type: 'text' | 'email' | 'tel' | 'password' = 'text';

  value: any = '';

  onChange: any = () => {};

  onTouched: any = () => {};

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onInput(event: any) {
    const value = event.target.value;
    this.value  = value;
    this.onChange(value);
  }

  public onBlur() {
    this.onTouched();
  }

  public onKeyDown(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (
      inputValue.length >= this.maxLength &&
      event.key.length === 1 &&
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)
    ) {
      event.preventDefault();
    } else if (inputValue.length > this.maxLength) {
      this.value = inputValue.substring(0, this.maxLength);
    }
  }
}
