import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  listCleManquante = [];

  handle(params: MissingTranslationHandlerParams) {
    if (this.listCleManquante.indexOf(params.key)===-1) {
      this.listCleManquante.push(params.key);
    }
    return `${params.key}`;
  }
}