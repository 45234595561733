import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
  transform(filename: string): string {
    return filename.split('.').pop();
  }
}
