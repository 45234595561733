import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { CodeWayPartnerService } from '@way-lib/common/services/code-waypartner.service';

export class CGdsInstallationRow extends JafRow {

  static INS_SIGN_IN_NOT_COMPLETED = 0

  static INS_SIGN_IN_COMPLETED = 1

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_ID%',
    class: 'Id',
  })
  public INS_ID;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_COMPTE%',
    class: 'Libelle',
  })
  public INS_COMPTE;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_SOCIETE%',
    class: 'Texte',
  })
  public INS_SOCIETE;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_GROUPE%',
    class: 'Texte',
  })
  public INS_GROUPE;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_URL%',
    class: 'Url',
  })
  public INS_URL;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_EMAIL%',
    class: 'Email',
  })
  public INS_EMAIL;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_ETAT%',
    class: 'Etat',
  })
  public INS_ETAT;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_LOGO%',
    class: 'Image',
  })
  public INS_LOGO;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_NOM_COMMERCIAL%',
    class: 'Texte',
  })
  public INS_NOM_COMMERCIAL;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_NUM_SIRET%',
    class: 'Texte',
  })
  public INS_NUM_SIRET;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_NUM_TVA%',
    class: 'Texte',
  })
  public INS_NUM_TVA_INTRA;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_CONTACT_NOM%',
    class: 'Texte',
  })
  public INS_CONTACT_NOM;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_CONTACT_PRENOM%',
    class: 'Texte',
  })
  public INS_CONTACT_PRENOM;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_TEL_MOBILE%',
    class: 'Texte',
  })
  public INS_TEL_MOBILE;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_FACT_ADRESSE%',
    class: 'Texte',
  })
  public INS_FACT_ADRESSE;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_FACT_CP%',
    class: 'Texte',
  })
  public INS_FACT_CP;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_FACT_VILLE%',
    class: 'Texte',
  })
  public INS_FACT_VILLE;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_FACT_PAYS%',
    class: 'Texte',
  })
  public INS_FACT_PAYS;

  @WayChamp({
    label  : '%LAB_GDS_INSTALLATION_FACT_PAY_ID%',
    class  : 'Liaison',
    liaison: 'C_Gds_Pays',
  })
  public INS_FACT_PAY_ID;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_FLAG_KM_DEBUT_SERVICE%',
    class: 'Flag',
  })
  public INS_FLAG_KM_DEBUT_SERVICE;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_CURRENCY%',
    class: 'Texte',
  })
  public INS_CURRENCY_SYMBOL;

  getCurrencyFromSymbol(): string {
    switch (this.INS_CURRENCY_SYMBOL) {
      case '€':
        return 'EUR';
      case '$':
        return 'USD';
      case '฿':
        return 'THB';
      case '£':
        return 'GBP';
      default :
        return this.INS_CURRENCY_SYMBOL;
    }
  }

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_FLAG_KM_DEMANDER%',
    class: 'Flag',
  })
  public INS_FLAG_KM_DEMANDER;

  private _urlLogo: string;

  get urlLogo(): string {
    if (!this._urlLogo) {
      this.build_urlLogo();
    }
    return this._urlLogo;
  }

  build_urlLogo() {
    const logo200x200 = this.INS_LOGO.replace(/^;?(.+)\.([a-z0-9]+)$/i, '$1-200x200.$2');
    this._urlLogo     = `https://api.waynium.com/images/bop/${logo200x200}`;
  }

  getLibelle(): string {
    return this.INS_NOM_COMMERCIAL || this.INS_COMPTE;
  }

  getInstallationCode(): string {
    return CodeWayPartnerService.getCodeByInsId(this.INS_ID);
  }
}
