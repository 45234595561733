import { JafRow } from '@way-lib-jaf/row';
import { CGenContactRow , CComCommandeRow } from '@way-lib-jaf/rowLoader';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { WayChampBuild } from '@way-lib/common/decorator/WayChampBuild';
import {TENANT_ID_NUMBER} from "@way-lib/common/constant";


export class CGenClientRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_CLIENT_ID%',
    class: 'Id',
  })
  public CLI_ID;

  @WayChamp({
    label  : '%LAB_GEN_CHAUFFEUR_PAR_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Partenaire',
    indexed: true,
  })
  public CLI_PAR_ID;

  @WayChamp({
    label: '%LAB_GEN_CLIENT_SOCIETE%',
    class: 'Texte',
  })
  public CLI_SOCIETE;

  @WayChamp({
    label: '%LAB_GEN_CLIENT_FACT_ADRESSE%',
    class: 'Texte',
  })
  public CLI_FACT_ADRESSE;

  @WayChampBuild('Index', {
    conceptName: 'C_Gen_Contact',
    indexName  : 'COT_CLI_ID',
  })
  public cots: Array<CGenContactRow>;

  private _cot: CGenContactRow;

  get cot(): CGenContactRow {
    if (this._cot === undefined || this._cot === null) {
      if (this.cots.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this._cot = this.cots[0];
      } else {
        return null;
      }
    }
    // console.log('get cot', this._cot);
    return this._cot;
  }

  set cot(cot) {
    // eslint-disable-next-line no-param-reassign
    cot.COT_CLI_ID = this;
    this._cot      = cot;
  }

  public hasCot() {
    return this.cot !== undefined && this.cot !== null;
  }

  public getGriId() {
      return Math.floor(this.CLI_ID/TENANT_ID_NUMBER) * TENANT_ID_NUMBER;
  }

  get clientFullName():string {
    return this.cot && this.cot.COT_PRENOM && this.cot.COT_NOM ? `${this.cot?.COT_PRENOM} ${this.cot?.COT_NOM}` : this.CLI_SOCIETE
  }
}

export interface ClientOrders {
  client: CGenClientRow;
  orders: Array<CComCommandeRow>;
}
