import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsChampMissionRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_CHAMPMISSION_ID%',
    class: 'Id',
  })
  public CMI_ID;

  @WayChamp({
    label: '%LAB_GDS_CHAMPMISSION_CLASS%',
    class: 'Texte',
  })
  public CMI_CLASS;

  @WayChamp({
    label: '%LAB_GDS_CHAMPMISSION_GROUPE%',
    class: 'Texte',
  })
  public CMI_GROUPE;

  @WayChamp({
    label: '%LAB_GDS_CHAMPMISSION_FLAG_PAX%',
    class: 'Flag',
  })
  public CMI_FLAG_PAX;

  @WayChamp({
    label: '%LAB_GDS_CHAMPMISSION_LABEL%',
    class: 'Libelle',
  })
  public CMI_LABEL;

  public count: number;
}
