import { JafRow } from '@way-lib-jaf/row';
import { CGdsInstallationRow } from '@way-lib-jaf/rowLoader';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenOptionclientRow extends JafRow {

  static OPT_ONE_YEAR = '84'

  static OPT_FREE = '83'

  static OPT_SIX_MONTHS = '85'

  static OPT_THREE_MONTHS = '86'

  static OPT_WEB_MONTHLY = '87'

  static OPT_APPLE_STORE_MONTHLY = '88'

  static OPT_GOOGLE_PLAY_MONTHLY = '89'

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_ID%',
    class: 'Id',
  })
  public OPI_ID;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_OPT_ID%',
    class: 'Quantite',
    // liaison: 'C_Gen_Option',
  })
  public OPI_OPT_ID; // CGenOptionRow ?

  @WayChamp({
    label  : '%LAB_GEN_OPTIONCLIENT_INS_ID%',
    class  : 'Liaison',
    liaison: 'C_Gds_Installation',
  })
  public OPI_INS_ID: CGdsInstallationRow;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_QUANTITE%',
    class: 'Quantite',
  })
  public OPI_QUANTITE;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_MONTANT%',
    class: 'Montant',
  })
  public OPI_MONTANT;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_REMISE%',
    class: 'Pourcentage',
  })
  public OPI_REMISE;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_LIBELLE%',
    class: 'Libelle',
  })
  public OPI_LIBELLE;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_JSON%',
    class: 'Object',
  })
  public OPI_JSON;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_COMMENTAIRE%',
    class: 'Textarea',
  })
  public OPI_COMMENTAIRE;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_DEBUT%',
    class: 'Date',
  })
  public OPI_DEBUT;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FIN%',
    class: 'Date',
  })
  public OPI_FIN;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_1%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_1;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_2%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_2;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_3%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_3;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_4%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_4;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_5%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_5;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_6%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_6;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_7%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_7;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_8%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_8;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_9%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_9;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_10%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_10;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_11%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_11;

  @WayChamp({
    label: '%LAB_GEN_OPTIONCLIENT_FACTURABLE_MOIS_12%',
    class: 'Flag',
  })
  public OPI_FACTURABLE_MOIS_12;
}
