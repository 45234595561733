import { Component, Input } from '@angular/core';

const createColorClasses = (color: string | undefined | null) => {
  return typeof color === 'string' && color.length > 0
    ? {
        'ion-color'           : true,
        [`ion-color-${color}`]: true,
      }
    : '';
};
@Component({
  selector   : 'way-underline',
  templateUrl: './way-underline.component.html',
  styleUrls  : ['./way-underline.component.scss'],
})
export class WayUnderlineComponent {
  @Input() text = '';

  @Input() icon = '';

  @Input() info = '';

  @Input() color = '';

  @Input() isLink = false

  colorClass: any;

  constructor() {
    this.colorClass = createColorClasses(this.color);
  }
}
