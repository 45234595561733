import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CComTypeServiceRow extends JafRow {

  static TSE_OTHER = 1;

  static TSE_DISPOSAL=2;

  static TSE_SERVICE = 3;

  static TSE_TOURISM = 4;

  static TSE_AIRPORT_PICKUP = 5;

  static TSE_AIRPORT_DROPOFF = 6;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_ID%',
    class: 'Id',
  })
  public TSE_ID;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_LIBELLE%',
    class: 'Libelle',
  })
  public TSE_LIBELLE;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_CODE%',
    class: 'Texte',
  })
  public TSE_CODE;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_FSE_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_ChorusFactureStatut',
  })
  public TSE_FSE_ID;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_COULEUR%',
    class: 'Couleur',
  })
  public TSE_COULEUR;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_MMI_ID%',
    class: 'Liaison',
    // liaison: 'C_Gen_ModeleMission',
  })
  public TSE_MMI_ID;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_TRI%',
    class: 'Tri',
  })
  public TSE_TRI;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_LIBELLE_SITE%',
    class: 'Texte',
  })
  public TSE_LIBELLE_SITE;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_INTRO%',
    class: 'Textarea',
  })
  public TSE_INTRO;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_DESCRIPTION%',
    class: 'Textarea',
  })
  public TSE_DESCRIPTION;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_A_PARTIR%',
    class: 'Texte',
  })
  public TSE_A_PARTIR;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_PHOTO%',
    class: 'Image',
  })
  public TSE_PHOTO;

  @WayChamp({
    label: '%LAB_COM_TYPESERVICE_FLAG_FORM_RESA%',
    class: 'Flag',
  })
  public TSE_FLAG_FORM_RESA;
}
