import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsMarqueRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_MARQUE_ID%',
    class: 'Id',
  })
  public MAR_ID;

  @WayChamp({
    label: '%LAB_GDS_MARQUE_LIBELLE%',
    class: 'Libelle',
  })
  public MAR_LIBELLE;
}