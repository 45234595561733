import { Component, Input } from '@angular/core';

@Component({
  selector   : 'way-clickable-phone-number',
  templateUrl: './way-clickable-phone-number.component.html',
  styleUrls  : ['./way-clickable-phone-number.component.scss'],
})
export class WayClickablePhoneNumberComponent {
  @Input() phoneNumber: number | string;

  @Input() county: string;

  @Input() mode: 'icon-only' | 'no-icon' | 'default' = 'default';

  @Input() isTel = true

  @Input() isSMS = true
}
