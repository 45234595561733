import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { JafRow } from '@way-lib-jaf/row';

export class CGenTypeVehiculeRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_ID%',
    class: 'Id',
  })
  public TVE_ID;

  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_CODE%',
    class: 'Texte',
  })
  public TVE_CODE;

  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_LIBELLE_COURT%',
    class: 'Texte',
  })
  public TVE_LIBELLE_COURT;

  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_PAX%',
    class: 'Quantite',
  })
  public TVE_PAX;

  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_TRI%',
    class: 'Tri',
  })
  public TVE_TRI;

  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_BAGAGE%',
    class: 'Quantite',
  })
  public TVE_BAGAGE;

  @WayChamp({
    label: '%TVE_A_PARTIR%',
    class: 'Texte',
  })
  public TVE_A_PARTIR;

  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_PHOTO%',
    class: 'Image',
  })
  public TVE_PHOTO;

  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_INTRO%',
    class: 'Textarea',
  })
  public TVE_INTRO;

  @WayChamp({
    label: '%LAB_GEN_TYPEVEHICULE_DESCRIPTION%',
    class: 'Textarea',
  })
  public TVE_DESCRIPTION;

  @WayChamp({
    label      : '%LAB_GEN_TYPEVEHICULE_LIBELLE%',
    class      : 'Libelle',
    multilingue: true,
  })
  public TVE_LIBELLE;
}
