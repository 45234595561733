import { Injectable } from '@angular/core';
import { CGenChauffeurRow } from '@way-lib-jaf/rowLoader';
import { ConceptManager } from '@way-lib-jaf/concept-manager'
import { AuthService } from '@way-lib/common/services/auth/auth.service';

export enum Language {
  FR = '1',
  EN = '2',
}

@Injectable({
  providedIn: 'root',
})
export class LangService {
  preferredLanguage: string;

  mainDriver: CGenChauffeurRow;

  constructor(private cm: ConceptManager, private authService: AuthService) {
    this.cm.setOnLoad(() => {
      this.mainDriver = this.cm.getConcept('C_Gen_Chauffeur').all[0];
      this.initializeFavoriteLang();
    });
  }

  initializeFavoriteLang() {
    this.switchLanguage(this.getDriverLang());
  }

  switchLanguage(language: string, reloadData: boolean = false) {
    this.setDriverLang(language);
    this.authService.loadChauffeurLanguage(+language);
    if (reloadData) {
      this.cm.loadDataLocale();
    }
  }

  getDriverLang(): any {
    const mainDriver = this.mainDriver || this.cm.getConcept('C_Gen_Chauffeur').all[0];
    return mainDriver?.CHU_LAN_ID;
  }

  setDriverLang(lang: string) {
    this.mainDriver?.setDatas({
      CHU_LAN_ID: lang,
    }, true);
  }

}
