import { JafConcept } from '@way-lib-jaf/concept';
import { CGenClientRow } from '@way-lib-jaf/rowLoader';

export class CGenClient extends JafConcept {
  public primary = 'CLI_ID';

  protected name = 'nf_gen_client';

  protected class = 'C_Gen_Client';

  protected rowClass = 'CGenClientRow';

  protected trigramme = 'CLI';

  getClientDefault() {
    const WayPartnerId  = this.cm.getInstallationPrincipal();
    let [clientDefault] = this.getRowsetByIndex('CLI_PAR_ID', WayPartnerId.INS_COMPTE, String(WayPartnerId.INS_ID * 1e11));
    if (!clientDefault) {
      clientDefault = new CGenClientRow(this, {}, WayPartnerId.INS_COMPTE);
      clientDefault.setDatas({
        CLI_ID     : clientDefault.getNewIncrement(),
        CLI_PAR_ID : WayPartnerId.INS_ID,
        CLI_SOCIETE: 'Default'
      });
    }
    return clientDefault;
  }
}
