import { CComCommandeRow, CGenClientRow, CGenMissionRow } from '@way-lib-jaf/rowLoader';
import { JafConcept } from '@way-lib-jaf/concept';

export class CComCommande extends JafConcept {
  public primary = 'COM_ID';

  protected name = 'nf_com_commande';

  protected class = 'C_Com_Commande';

  protected rowClass = 'CComCommandeRow';

  protected trigramme = 'COM';

  get pending(): CComCommandeRow[] {
    let pendingOrders: CComCommandeRow[] = [];

    [
      CComCommandeRow.SCO_IN_PROGRESS,
      CComCommandeRow.SCO_SENT_TO_SUBCONTRACTOR,
      CComCommandeRow.SCO_QUOTE_OK,
    ].forEach((status) => {
      const orders  = this.getOrderByStatus(status);
      pendingOrders = pendingOrders.concat(orders);
    });

    return pendingOrders;
  }

  get accepted(): CComCommandeRow[] {
    return this.getOrderByStatus(CComCommandeRow.SCO_QUOTE_CONFIRMED);
  }

  get cancelled(): CComCommandeRow[] {
    return this.getOrderByStatus(CComCommandeRow.SCO_CANCELLED);
  }

  get invoiceOk(): CComCommandeRow[] {
    return this.getOrderByStatus(CComCommandeRow.SCO_INVOICE_OK);
  }

  get invoicePaid(): CComCommandeRow[] {
    return this.getOrderByStatus(CComCommandeRow.SCO_INVOICE_PAID);
  }

  getOrderByStatus(status: string): CComCommandeRow[] {
    return <CComCommandeRow[]>this.all.filter((order: CComCommandeRow) => {
      return order.COM_SCO_ID === status;
    });
  }

  public findCommandeFor(
    client: CGenClientRow,
    mission: CGenMissionRow,
    database,
  ): CComCommandeRow {
    let status;
    switch (mission.MIS_SMI_ID.toString()) {
      case CGenMissionRow.SMI_ATTENTE_ATTRIBUTION:
        status = CComCommandeRow.SCO_QUOTE_CONFIRMED;
        break;
      case CGenMissionRow.SMI_ENVOYER_AU_CHAUFFEUR:
        status = CComCommandeRow.SCO_QUOTE_CONFIRMED;
        break;
      case CGenMissionRow.SMI_CHAUFFEUR_OK:
        status = CComCommandeRow.SCO_QUOTE_CONFIRMED;
        break;
      case CGenMissionRow.SMI_ANNULER:
        status = CComCommandeRow.SCO_CANCELLED;
        break;
      default:
        status = CComCommandeRow.SCO_IN_PROGRESS;
        break;
    }

    if (mission.MIS_COM_ID) {
      mission.MIS_COM_ID.setDatas({
        COM_SCO_ID: status,
        COM_CLI_ID: client,
        COM_GRI_ID: client.getGriId(),
        COM_COT_ID: client.cot ? client.cot.COT_ID : 0,
      });
      return mission.MIS_COM_ID;
    }

    const newCommande = new CComCommandeRow(this, {}, database);
    newCommande.setDatas({
      COM_SCO_ID: status,
      COM_CLI_ID: client,
      COM_GRI_ID: client.getGriId(),
      COM_COT_ID: client.cot ? client.cot.COT_ID : 0,
      COM_MDE_ID: 1,
      COM_TCO_ID: 1,
      COM_TDO_ID: 1,
      COM_COL_ID: 2,
    });

    return newCommande;
  }
}
