import { JafConcept } from '@way-lib-jaf/concept';

export class CGenPlageInterditeIndispo extends JafConcept {
  public primary = 'PII_ID';

  protected name = 'nf_gen_plageInterditeIndispo';

  protected class = 'C_Gen_PlageInterditeIndispo';

  protected rowClass = 'CGenPlageInterditeIndispoRow';

  protected trigramme = 'PII';

}