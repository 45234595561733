export const PRICE_RANGE_CONSTANTS = {
  MIN_VALUE   : 0,
  MAX_VALUE   : 100,
  MIDDLE_VALUE: 50,
  STEP_SIZE   : 1,

  LOW_THRESHOLD : 30,
  HIGH_THRESHOLD: 70,

  MAX_PRICE_MULTIPLIER     : 3,
  ABOVE_ESTIMATE_MULTIPLIER: 2,

  PRICE_DECIMALS: 0,

  BELOW_ESTIMATE_SCALE: 50,
  ABOVE_ESTIMATE_SCALE: 50,
} as const;
