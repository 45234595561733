import { Pipe, PipeTransform } from '@angular/core';
import { CGenCategorieChauffeurRow } from '../services/jaf/rowLoader'

@Pipe({
  name: 'language',
})
export class LanguagePipe implements PipeTransform {
  transform(items: Array<CGenCategorieChauffeurRow>, keyword: string): Array<CGenCategorieChauffeurRow> {
    return items?.filter((item: CGenCategorieChauffeurRow) => {
      if (!keyword) {
        return true;
      }
      const normalizedKeyword = this.normalizeString(keyword);
      const normalizedItem    = this.normalizeString(item?.CCH_LIBELLE);
      return normalizedItem.toLowerCase().includes(normalizedKeyword.toLowerCase());
    });
  }

  private normalizeString(str: string): string {
    return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
}
