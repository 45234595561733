import { Component, Input } from '@angular/core';
import { IonContent } from '@ionic/angular';

interface SegmentScrollItem {
  value: string;
  label: string;
}

@Component({
  selector   : 'way-segment-scroll',
  templateUrl: './way-segment-scroll.component.html',
  styleUrls  : ['./way-segment-scroll.component.scss'],
})
export class WaySegmentScrollComponent {
  @Input() items: Array<SegmentScrollItem>;

  @Input() segmentValue: string;

  @Input() content: IonContent;

  segmentChanged(event: CustomEvent) {
    const segmentValue = event.detail.value;
    this.scrollTo(segmentValue);
  }

  private scrollTo(elementId: string) {
    const y = document.getElementById(elementId).getBoundingClientRect().top;
    this.content.scrollToPoint(0, y);
  }
}
