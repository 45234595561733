import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsEquIns extends JafConcept {
  public primary = 'EIN_ID';

  protected name = 'nf_gds_equIns';

  protected class = 'C_Gds_EquIns';

  protected rowClass = 'CGdsEquInsRow';

  protected trigramme = 'EIN';

}