import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsEquInsRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_EQUINS_ID%',
    class: 'Id',
  })
  public EIN_ID;

  @WayChamp({
    label: '%LAB_GDS_EQUINS_INS_ID%',
    class: 'Liaison',
  })
  public EIN_INS_ID;

  @WayChamp({
    label: '%LAB_GDS_EQUINS_EQU_ID%',
    class: 'Liaison',
  })
  public EIN_EQU_ID;
}