import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { AuthService } from '@way-lib/common/services/auth/auth.service';
import { AppComponent } from '../../../app.component';

@Component({
  selector   : 'app-signin',
  templateUrl: './signin.page.html',
  styleUrls  : ['./signin.page.scss'],
})
export class SigninPage implements OnInit {
  signinForm = new UntypedFormGroup({
    login   : new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  submitting = false;

  loginError: any;

  version: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    public appComponent: AppComponent,
    public cm: ConceptManager,
  ) {}

  ngOnInit() {
    this.version = this.appComponent.version;
    this.authService.isAuthenticated().then((authenticated) => {
      if (authenticated) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  onSubmit() {
    if (!this.signinForm.valid) {
      return;
    }
    this.loginError = null;
    this.submitting = true;
    this.authService
      .login(this.signinForm.value)
      .then(() => {
        this.loginError = 'Ok';
        this.appComponent.execute();
        this.submitting = false;
        this.cm.detectChanges();
      })
      .catch((error) => {
        this.authService.popupAlert(error.message);
        this.loginError = error;
        this.submitting = false;
        this.cm.detectChanges();
      });
  }

  showErrors(control: string) {
    const { dirty, touched, errors } = this.signinForm.get(control);
    return dirty && touched && errors;
  }
}
