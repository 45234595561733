import { Component, Input } from '@angular/core';

@Component({
  selector   : 'way-partner-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrls  : ['./subtitle.component.scss'],
})
export class SubtitleComponent {
  @Input() title: string;
}
