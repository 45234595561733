import { JafRow } from '@way-lib-jaf/row';
import { CComTypeServiceRow , CComCategorieRow } from '@way-lib-jaf/rowLoader'
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CComServiceRow extends JafRow {
  @WayChamp({
    label: '%LAB_COM_SERVICE_ID%',
    class: 'Id',
  })
  public SER_ID;

  @WayChamp({
    label  : '%LAB_COM_SERVICE_CAT_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_Categorie',
    indexed: true,
  })
  public SER_CAT_ID: CComCategorieRow;

  @WayChamp({
    label: '%LAB_COM_SERVICE_LIBELLE%',
    class: 'Libelle',
  })
  public SER_LIBELLE;

  @WayChamp({
    label  : '%LAB_COM_SERVICE_TSE_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_TypeService',
    indexed: true,
  })
  public SER_TSE_ID: CComTypeServiceRow;

  @WayChamp({
    label: '%LAB_COM_SERVICE_KM_INCLUS%',
    class: 'Quantite',
  })
  public SER_KM_INCLUS: any;

  @WayChamp({
    label: '%LAB_COM_SERVICE_HEURE_INCLUS%',
    class: 'Quantite',
  })
  public SER_HEURE_INCLUS: any;

  @WayChamp({
    label: '%LAB_COM_SERVICE_TRI%',
    class: 'Tri',
  })
  public SER_TRI;

  @WayChamp({
    label: '%LAB_COM_SERVICE_DESCRIPTION%',
    class: 'Textarea',
  })
  public SER_DESCRIPTION: any;


}
