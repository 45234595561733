import { JafConcept } from '@way-lib-jaf/concept';
import { CComFactureRow } from '@way-lib-jaf/rowLoader';

export class CComFacture extends JafConcept {
  public primary = 'FAC_ID';

  protected name = 'nf_com_facture';

  protected class = 'C_Com_Facture';

  protected rowClass = 'CComFactureRow';

  protected trigramme = 'FAC';

  get generated(): Array<CComFactureRow> {
    const factures: Array<CComFactureRow> = [];
    this.all.forEach((fac: CComFactureRow) => {
      if (fac.FAC_DATE) {
        factures.push(fac);
      }
    });
    return factures;
  }
}
