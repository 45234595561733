import { JafConcept } from '@way-lib-jaf/concept';

export class CGenCatCha extends JafConcept {
  public primary = 'CCA_ID';

  protected name = 'nf_gen_catCha';

  protected class = 'C_Gen_CatCha';

  protected rowClass = 'CGenCatChaRow';

  protected trigramme = 'CCA';
}
