/* pour type=Index => exemple pour
        options : {
            conceptName: 'C_Gen_EtapePresence',
            indexName: 'EPR_MIS_ID',
            tri: 'EPR_TRI',
        }
*/

export function WayChampBuild(type?, options?) {
  return (target: any, key: string) => {
    if (!target.listeBuild) {
      // eslint-disable-next-line no-param-reassign
      target.listeBuild = [];
    }
    // eslint-disable-next-line no-param-reassign
    target.listeBuild.push([key, type, options]);
  };
}
