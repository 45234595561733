import { formatDate } from '@angular/common';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { Jaf } from '@way-lib-jaf/jaf';

export class CGenPlageInterditeIndispoRow extends JafRow {
  @WayChamp({
    label: 'id',
    class: 'Id',
  })
  public PII_ID;


  @WayChamp({
    label: 'libelle',
    class: 'Texte',
  })
  public PII_LIBELLE;


  @WayChamp({
    label: 'Date debut',
    class: 'Date',
  })
  public PII_DATE_DEBUT;

  @WayChamp({
    label: 'heure debut',
    class: 'Time',
  })
  public PII_HEURE_DEBUT;

  @WayChamp({
    label: 'date fin',
    class: 'Date',
  })
  public PII_DATE_FIN;

  @WayChamp({
    label: 'heure fin',
    class: 'Time',
  })
  public PII_HEURE_FIN;


  getDateDebut() {
    const dateDebut = new Date( (Jaf.getDate(this.PII_DATE_DEBUT).getTime() / 1000 + Jaf.getTemps(this.PII_HEURE_DEBUT)) * 1000);
    return `${formatDate(dateDebut, 'fullDate', Jaf.LAN_CODE)}, ${formatDate(dateDebut, 'shortTime', Jaf.LAN_CODE)}`;
  }

  getDateFin() {
    const dateFin = new Date( (Jaf.getDate(this.PII_DATE_FIN).getTime() / 1000 + Jaf.getTemps(this.PII_HEURE_FIN)) * 1000);
    return `${formatDate(dateFin, 'fullDate', Jaf.LAN_CODE)}, ${formatDate(dateFin, 'shortTime', Jaf.LAN_CODE)}`;
  }
}
