import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector   : 'tabler-icon',
  templateUrl: './tabler-icon.component.html',
  styleUrls  : ['./tabler-icon.component.scss'],
})
export class TablerIconComponent implements OnInit, OnChanges {
  @Input() icon: string;

  @Input() size: string;

  @Input() color: string;

  xlinkHref: string;

  ngOnInit() {
    this.xlinkHref = `/assets/icons/tabler/tabler-sprite.svg#tabler-${this.icon}`;
  }

  ngOnChanges() {
    this.xlinkHref = `/assets/icons/tabler/tabler-sprite.svg#tabler-${this.icon}`;
  }
}
