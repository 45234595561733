import { Pipe, PipeTransform } from '@angular/core';
import { Jaf } from '../services/jaf/jaf';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(input: unknown): string {
    if (undefined === input) return '';
    const date1 = Jaf.getDate(input);
    const date2 = new Date();
    const tmp   = date2.getTime() - date1.getTime();
    const sec   = Math.floor(tmp / 1000);
    const min   = Math.floor(sec / 60);
    const hour  = Math.floor(min / 60);
    const day   = Math.floor(hour / 24);

    if (day > 0) {
      return `${day} ${day > 1 ? 'jours ' : 'jour'}`;
    }
    if (hour > 1) {
      return `${hour} heures`;
    }
    if (min > 1) {
      return `${min} minutes`;
    }
    return `quelques instants`;
  }
}
