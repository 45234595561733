import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@way-lib/common/services/auth/auth.guard';
import { ForgotPasswordPage } from './forgot-password/forgot-password.page';
import { SigninPage } from './signin/signin.page';
import { SignupPage } from './signup/signup.page';

const routes: Routes = [
  {
    path     : 'signin',
    component: SigninPage,
  },
  {
    path        : 'signup',
    component   : SignupPage,
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfilePageModule),
  },
  {
    path        : 'profile',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfilePageModule),
  },
  {
    path     : 'forgot-password',
    component: ForgotPasswordPage,
  },
  {
    path      : '',
    redirectTo: 'signin',
    pathMatch : 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
