import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { WayLibModule } from '@way-lib/way-lib.module';
import { SignupPageRoutingModule } from './signup-routing.module';

import { SignupPage } from './signup.page';
import { ProfileFormModule } from '../profile/profile-form/profile-form.module';

@NgModule({
  imports     : [CommonModule, IonicModule, SignupPageRoutingModule, ProfileFormModule, WayLibModule],
  declarations: [SignupPage],
})
export class SignupPageModule {}
