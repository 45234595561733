import { Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector   : 'way-flag',
  templateUrl: './flag.component.html',
  styleUrls  : ['./flag.component.scss'],
})
export class FlagComponent implements OnChanges {
  @Input() lanId: number | string;

  @Input() countryISO2: string;

  @Input() variant: 'normal' | 'secondary' = 'normal';

  flagUrl;

  constructor(private httpClient: HttpClient) {}

  get lanCode(): string {
    if (!this.lanId) return this.countryISO2;

    switch (this.lanId.toString()) {
      case '3':
        return 'EN';
      case '4':
        return 'FR';
      case '5':
        return 'DE';
      case '6':
        return 'ES';
      case '7':
        return 'IT';
      case '8':
        return 'JP';
      case '10':
        return 'NL';
      case '11':
        return 'RU';
      default:
        return '';
    }
  }

  ngOnChanges() {
    if (this.lanCode) {
      this.flagUrl = this.getFlagUrl(this.lanCode);
    }
  }

  getFlagUrl(lanCode: string) {
    const file = `assets/flag/flag_${lanCode}.gif`;
    if (this.fileExists(file)) {
      return file;
    }
    return null;
  }

  fileExists(url: string): Observable<boolean> {
    return this.httpClient.get(url).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }
}
