import { Component, Input } from '@angular/core';
import { CGenVoitureRow } from '@way-lib-jaf/rowLoader';

@Component({
  selector   : 'way-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls  : ['./vehicle.component.scss'],
})
export class VehicleComponent {
  @Input() vehicle: CGenVoitureRow;

  @Input() withIcon = false;
}
