import { Pipe, PipeTransform } from '@angular/core';

// Définit le caractère, la base, etc.
@Pipe({name: 'numReservation'})
export class NumReservationPipe implements PipeTransform {
  static charset = "GH5ABC6ED3FJUK8N7MPQ9RS2TVW1XYZ4";

  transform(ins_id: number, mis_id: number): string {
    // eslint-disable-next-line no-bitwise
    let num         = (ins_id << 16) + (mis_id % (1 << 16));
    let reversedNum = 0;

    // Boucle sur 30 bits
    for (let i = 0; i < 30; i++) {
      // eslint-disable-next-line no-bitwise
      reversedNum <<= 1;

      // eslint-disable-next-line eqeqeq
      if ((i % 2 === 0 && num % 2 == 0) || (i % 2 === 1 && num % 2 == 1)) {
        reversedNum++;
      }

      // eslint-disable-next-line no-bitwise
      num >>= 1;
    }

    let result = "";

    // On construit la chaîne en partant de la fin
    for (let i = 0; i < 6; i++) {
      // Calcul de l'index du caractère correspondant
      const index = reversedNum % NumReservationPipe.charset.length;
      result = (i === 2 ? '-' : '') + NumReservationPipe.charset[index] + result;

      // Division entière pour passer à l'étape suivante
      reversedNum = Math.floor(reversedNum / NumReservationPipe.charset.length);
    }

    return result;
  }
}