import { CGdsEnergieRow } from '@way-lib-jaf/rowLoader';
import { JafConcept } from '@way-lib-jaf/concept';
import { Jaf } from '@way-lib-jaf/jaf'

export class CGdsEnergie extends JafConcept {
  public primary = 'ENE_ID';

  protected name = 'nf_gds_energie';

  protected class = 'C_Gds_Energie';

  protected rowClass = 'CGdsEnergieRow';

  protected trigramme = 'ENE';

  getEnergyLabelById(energyId: string): string {
    const label = (<CGdsEnergieRow[]>this.all).find((energy) => energy.ENE_ID === energyId)?.ENE_LIBELLE;
    return label ? Jaf.translate(label) : '';
  }
}
