import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsEquipement extends JafConcept {
  public primary = 'EQU_ID';

  protected name = 'nf_gds_equipement';

  protected class = 'C_Gds_Equipement';

  protected rowClass = 'CGdsEquipementRow';

  protected trigramme = 'EQU';

  public _flagUniqueRowset = true;
}