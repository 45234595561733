import { formatDate } from '@angular/common';
import { JafRow } from '@way-lib-jaf/row';
import { Jaf } from '@way-lib-jaf/jaf';
import { CGenTypeRegleAgendaRow } from '@way-lib-jaf/rowLoader';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenAgendaChauffeurRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_ID%',
    class: 'Id',
  })
  public ACH_ID;

  @WayChamp({
    label  : '%LAB_CHAUFFEUR%',
    class  : 'Texte',
    indexed: true,
  })
  public ACH_CHU_ID:number;

  @WayChamp({
    label  : '%LAB_GEN_AGENDACHAUFFEUR_TYE_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_TypeRegleAgenda',
  })
  public ACH_TYE_ID: CGenTypeRegleAgendaRow;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_LIBELLE%',
    class: 'Texte',
  })
  public ACH_LIBELLE;

  @WayChamp({
    label: 'Recurrence',
    class: 'Texte',
  })
  public ACH_RECURRENCE;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_J1%',
    class: 'Flag',
  })
  public ACH_J1;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_J2%',
    class: 'Flag',
  })
  public ACH_J2;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_J3%',
    class: 'Flag',
  })
  public ACH_J3;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_J4%',
    class: 'Flag',
  })
  public ACH_J4;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_J5%',
    class: 'Flag',
  })
  public ACH_J5;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_J6%',
    class: 'Flag',
  })
  public ACH_J6;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_J0%',
    class: 'Flag',
  })
  public ACH_J0;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_REPEAT%',
    class: 'Quantite',
  })
  public ACH_REPEAT;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_DATE_DEBUT%',
    class: 'Date',
  })
  public ACH_DATE_DEBUT;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_HEURE_DEBUT%',
    class: 'Time',
  })
  public ACH_HEURE_DEBUT;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_DATE_FIN%',
    class: 'Date',
  })
  public ACH_DATE_FIN;

  @WayChamp({
    label: '%LAB_GEN_AGENDACHAUFFEUR_HEURE_FIN%',
    class: 'Time',
  })
  public ACH_HEURE_FIN;

  @WayChamp({
    label: 'Statut agenda chauffeur',
    class: 'Texte',
  })
  public ACH_SAC_ID;


  /** Calendar */
  get start() {
    return new Date( (Jaf.getDate(this.ACH_DATE_DEBUT).getTime() / 1000 + Jaf.getTemps(this.ACH_HEURE_DEBUT)) * 1000);
  }

  /** Calendar */
  get end() {
    return new Date( (Jaf.getDate(this.ACH_DATE_FIN).getTime() / 1000 + Jaf.getTemps(this.ACH_HEURE_FIN)) * 1000);
  }

  /** Calendar */
  color = {
    primary  : '#000000',
    secondary: '#D0D0D0',
  };

  /** Calendar */
  allDay: false;

  /** Calendar */
  resizable: {
    beforeStart: false;
    afterEnd: false;
  };

  /** Calendar */
  get title(): string {
    const tyeLibelle = this.concept.cm.translate.instant(Jaf.translate(this.ACH_TYE_ID.TYE_LIBELLE));
    return `${tyeLibelle} ${this.ACH_LIBELLE}`;
  }

  /** Calendar */
  draggable: false;

  getDateDebut() {
    const date  = Jaf.getDate(this.ACH_DATE_DEBUT).getTime();
    const date2 = new Date( (date / 1000 + Jaf.getTemps(this.ACH_HEURE_DEBUT)) * 1000);
    return `${formatDate(date2, 'fullDate', Jaf.LAN_CODE)}, ${formatDate(date2, 'shortTime', Jaf.LAN_CODE)}`;
  }

  getDateFin() {
    const date  = Jaf.getDate(this.ACH_DATE_FIN).getTime();
    const date2 = new Date( (date / 1000 + Jaf.getTemps(this.ACH_HEURE_FIN)) * 1000);
    return `${formatDate(date2, 'fullDate', Jaf.LAN_CODE)}, ${formatDate(date2, 'shortTime', Jaf.LAN_CODE)}`;
  }

  statutLibelle() {
    if (this.ACH_SAC_ID==='2') return "Acceptée";
    if (this.ACH_SAC_ID==='1' || this.ACH_SAC_ID==='4' ) return "Envoyée";
    return "Refusée";
  }

  icone() {
    if (this.ACH_SAC_ID==='2') return "ri-checkbox-circle-line";
    if (this.ACH_SAC_ID==='1' || this.ACH_SAC_ID==='4' ) return "ri-question-mark";
    return "ri-close-circle-line";
  }

  colorIcone() {
    if (this.ACH_SAC_ID==='2') return "ion-color-success";
    if (this.ACH_SAC_ID==='1' || this.ACH_SAC_ID==='4' ) return "ion-color-warning";
    return "ion-color-danger";
  }

}
