import { JafConcept } from '@way-lib-jaf/concept';

export class CGenPartenaire extends JafConcept {
  public primary = 'PAR_ID';

  protected name = 'nf_gen_partenaire';

  protected class = 'C_Gen_Partenaire';

  protected rowClass = 'CGenPartenaireRow';

  protected trigramme = 'PAR';

  getInternalPartner() {
    const WayPartnerId =  this.cm.getInstallationPrincipal();
    return this.getRow(String(WayPartnerId.INS_ID * 1e11), WayPartnerId.INS_COMPTE);
  }
}