import { JafRow } from '@way-lib-jaf/row';
import { CComModeReglementRow } from '@way-lib-jaf/rowLoader';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CComIbanRow extends JafRow {
  @WayChamp({
    label: '%LAB_COM_IBAN_ID%',
    class: 'Id',
  })
  public IBA_ID;

  @WayChamp({
    label: '%LAB_COM_IBAN_LIBELLE%',
    class: 'Texte',
  })
  public IBA_LIBELLE;

  @WayChamp({
    label: '%LAB_COM_IBAN_COMPTE%',
    class: 'Texte',
  })
  public IBA_COMPTE;

  @WayChamp({
    label: '%LAB_COM_IBAN_IBAN%',
    class: 'Texte',
  })
  public IBA_IBAN;

  @WayChamp({
    label: '%LAB_COM_IBAN_BIC%',
    class: 'Texte',
  })
  public IBA_BIC;

  @WayChamp({
    label  : '%LAB_COM_IBAN_MRE_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_ModeReglement',
  })
  public IBA_MRE_ID: CComModeReglementRow;
}
