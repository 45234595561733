import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsPays extends JafConcept {
  public primary = 'PAY_ID';

  protected name = 'nf_gds_pays';

  protected class = 'C_Gds_Pays';

  protected rowClass = 'CGdsPaysRow';

  protected trigramme = 'PAY';
}
