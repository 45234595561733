export interface WayChampParams {
  label: string;
  class:
    | 'Id'
    | 'Date'
    | 'Datetime'
    | 'Liaison'
    | 'Object'
    | 'Libelle'
    | 'Couleur'
    | 'Image'
    | 'Textarea'
    | 'Texte'
    | 'Fichier'
    | 'Motdepasse'
    | 'Ville'
    | 'Email'
    | 'Codepostal'
    | 'Telephone'
    | 'Time'
    | 'Etat'
    | 'Flag'
    | 'Url'
    | 'FlagNewsletter'
    | 'Tri'
    | 'Montant'
    | 'Quantite'
    | 'Pourcentage';
  default?: any;
  indexed?: any;
  multilingue?: any;
  liaison?: string;
  builds?: Array<string>;
}

export function WayChamp(params: WayChampParams) {
  return (target: any, key: string) => {
    // eslint-disable-next-line no-param-reassign
    target.setChamp(key, params);
  };
}
