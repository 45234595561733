import { JafConcept } from '@way-lib-jaf/concept';

export class CComFraisMission extends JafConcept {
  public primary = 'FMI_ID';

  protected name = 'nf_com_fraisMission';

  protected class = 'C_Com_FraisMission';

  protected rowClass = 'CComFraisMissionRow';

  protected trigramme = 'FMI';
}

export enum PriceType {
  TTC = 'TTC',
  HT  = 'HT',
}