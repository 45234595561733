import { JafConcept } from '@way-lib-jaf/concept';

export class CComTypeService extends JafConcept {
  public primary = 'TSE_ID';

  protected name = 'nf_com_typeService';

  protected class = 'C_Com_TypeService';

  protected rowClass = 'CComTypeServiceRow';

  protected trigramme = 'TSE';
}
