import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PRICE_RANGE_CONSTANTS } from './price-range.constants';

@Component({
  selector   : 'price-range',
  templateUrl: './price-range.component.html',
  styleUrls  : ['./price-range.component.scss'],
})
export class PriceRangeComponent {
  @Input() control: FormControl;

  @Input() isDisabled: boolean = false;

  @Output() rangeChange = new EventEmitter<Event>();

  public PRICE_RANGE_CONSTANTS = PRICE_RANGE_CONSTANTS;

  onRangeChange(event: Event) {
    this.rangeChange.emit(event);
  }
}
