import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenPartenaireRow extends JafRow {
  @WayChamp({
    label: '%LAB_PAR_PARTENAIRE_ID%',
    class: 'Id',
  })
  public PAR_ID;

  @WayChamp({
    label: '%LAB_GEN_PARTENAIRE_LIBELLE%',
    class: 'Texte',
  })
  public PAR_LIBELLE;
}
