import { Component, Input } from '@angular/core';

@Component({
  selector   : 'way-logo',
  templateUrl: './way-logo.component.html',
  styleUrls  : ['./way-logo.component.scss'],
})
export class WayLogoComponent {
  @Input() color: string;
}
