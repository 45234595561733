import { CGenClientRow , CGenCiviliteRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenContactRow extends JafRow {

  static LAN_FRENCH = '1'

  static LAN_ENGLISH = '2'

  @WayChamp({
    label: '%LAB_GEN_CONTACT_ID%',
    class: 'Id',
  })
  public COT_ID;

  @WayChamp({
    label  : '%LAB_GEN_CONTACT_CLI_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Client',
    indexed: true,
  })
  public COT_CLI_ID: CGenClientRow;

  @WayChamp({
    label  : '%LAB_GEN_CONTACT_CIV_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Civilite',
  })
  public COT_CIV_ID: CGenCiviliteRow;

  @WayChamp({
    label: '%LAB_GEN_CONTACT_LAN_ID%',
    class: 'Liaison',
    // liaison: 'C_Cms_Langue',
  })
  public COT_LAN_ID;

  @WayChamp({
    label: '%LAB_GEN_CONTACT_NOM%',
    class: 'Texte',
  })
  public COT_NOM;

  @WayChamp({
    label: '%LAB_GEN_CONTACT_PRENOM%',
    class: 'Texte',
  })
  public COT_PRENOM;

  @WayChamp({
    label: '%LAB_GEN_CONTACT_EMAIL%',
    class: 'Email',
  })
  public COT_EMAIL;

  @WayChamp({
    label: '%LAB_GEN_CONTACT_TELEPHONE%',
    class: 'Telephone',
  })
  public COT_TELEPHONE;

  @WayChamp({
    label: '%LAB_GEN_CONTACT_MOBILE%',
    class: 'Telephone',
  })
  public COT_MOBILE;
}
