import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  COUNTRY_MAPPINGS = {
    BE: 'Belgique',
    CG: 'Congo',
    FR: 'France',
    DE: 'Allemagne',
    IT: 'Italie',
    MA: 'Maroc',
    NC: 'Nouvelle-Calédonie',
    SN: 'Sénégal',
    ES: 'Espagne',
    CH: 'Suisse',
    TH: 'Thaïlande',
    AE: 'Émirats arabes unis',
    EG: 'Égypte',
    GB: 'Royaume-Uni',
  };

  transform(value: any): any {
    return this.COUNTRY_MAPPINGS[value] || value;
  }
}
