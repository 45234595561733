import { JafConcept } from '@way-lib-jaf/concept';

export class CComGrille extends JafConcept {
  public primary = 'GRI_ID';

  protected name = 'nf_com_grille';

  protected class = 'C_Com_Grille';

  protected rowClass = 'CComGrilleRow';

  protected trigramme = 'GRI';
}
