import { JafRow } from '@way-lib-jaf/row';
import { CGenChauffeurRow , CGenCategorieChauffeurRow } from '@way-lib-jaf/rowLoader';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGenCatChaRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_CATCHA_ID%',
    class: 'Id',
  })
  public CCA_ID;

  @WayChamp({
    label  : '%LAB_GEN_CATCHA_CHU_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Chauffeur',
    indexed: true,
  })
  public CCA_CHU_ID: CGenChauffeurRow;

  @WayChamp({
    label  : '%LAB_GEN_CATCHAT_CCH_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_CategorieChauffeur',
  })
  public CCA_CCH_ID: CGenCategorieChauffeurRow;
}
