import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CComCategorieRow extends JafRow {

  static CAT_MISSION = '1';

  static CAT_KM_SUPP = '3';

  @WayChamp({
    label: '%LAB_COM_CATEGORIE_ID%',
    class: 'Id',
  })
  public CAT_ID;

  @WayChamp({
    label: '%LAB_COM_CATEGORIE_LIBELLE%',
    class: 'Libelle',
  })
  public CAT_LIBELLE;

  @WayChamp({
    label: '%LAB_COM_CATEGORIE_ICONE%',
    class: 'Texte',
  })
  public CAT_ICONE;

  @WayChamp({
    label: '%LAB_COM_CATEGORIE_TVA_ID%',
    class: 'Liaison',
    // liaison: 'C_Com_Tva',
  })
  public CAT_TVA_ID;

  @WayChamp({
    label: '%LAB_COM_CATEGORIE_TRI%',
    class: 'Tri',
  })
  public CAT_TRI;

  @WayChamp({
    label: '%LAB_COM_CATEGORIE_FLAG_TABLEAU_DEVIS%',
    class: 'Flag',
  })
  public CAT_FLAG_TABLEAU_DEVIS;

  @WayChamp({
    label: '%LAB_COM_CATEGORIE_FLAG_PL%',
    class: 'Flag',
  })
  public CAT_FLAG_PL;

}
