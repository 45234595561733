import { Subject } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';
import { JafConcept } from '@way-lib-jaf/concept';
import { CGenAgendaChauffeurRow , CGenTypeRegleAgendaRow } from '@way-lib-jaf/rowLoader';

enum Day {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

enum Shift {
  NIGHT = 'night',
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

enum DayActive {
  ACTIVE = '1',
  INACTIVE = '0',
}

interface DaySchedule {
  name: string;
  slots: DayScheduleSlots;
}

interface DayScheduleSlots {
  night: boolean;
  morning: boolean;
  afternoon: boolean;
  evening: boolean;
}

interface Slot {
  day: Day;
  shift: Shift;
}

const SHIFT_TIMES = {
  [Shift.NIGHT]    : { start: '00:00:00', end: '06:00:00' },
  [Shift.MORNING]  : { start: '06:00:00', end: '12:00:00' },
  [Shift.AFTERNOON]: { start: '12:00:00', end: '18:00:00' },
  [Shift.EVENING]  : { start: '18:00:00', end: '23:59:59' },
};

const DEFAULT_RECURRING = 5;
const DEFAULT_REPEAT    = 1;

export class CGenAgendaChauffeur extends JafConcept {
  public primary = 'ACH_ID';

  protected name = 'nf_gen_agendaChauffeur';

  protected class = 'C_Gen_AgendaChauffeur';

  protected rowClass = 'CGenAgendaChauffeurRow';

  protected trigramme = 'ACH';

  public _flagUniqueRowset = true;

  private _listeValide: Array<CGenAgendaChauffeurRow>;

  private _calendar: Subject<Array<CalendarEvent>>;

  private _availabilities: Array<CGenAgendaChauffeurRow>;

  private _unavailabilities: Array<CGenAgendaChauffeurRow>;

  get availabilities() {
    if (this._availabilities === undefined) {
      this.build_availabilities();
    }
    return this._availabilities;
  }

  build_availabilities() {
    if (this._availabilities === undefined) {
      this._availabilities = new Array<CGenAgendaChauffeurRow>();

      this.bindfilter(['ACH_ID'], ['build_availabilities']);
    }
    this.arrayMerge(
      this._availabilities,
      this.all.filter((agenda: CGenAgendaChauffeurRow) => {
        const typeId =
          typeof agenda.ACH_TYE_ID === 'object' ? agenda.ACH_TYE_ID.TYE_ID : agenda.ACH_TYE_ID;
        return typeId === CGenTypeRegleAgendaRow.TYE_AVAILABLE;
      }),
    );
  }

  get unavailabilities() {
    if (this._unavailabilities === undefined) {
      this.build_unavailabilities();
    }
    return this._unavailabilities;
  }

  build_unavailabilities() {
    if (this._unavailabilities === undefined) {
      this._unavailabilities = new Array<CGenAgendaChauffeurRow>();

      this.bindfilter(['ACH_ID'], ['build_unavailabilities']);
    }
    this.arrayMerge(
      this._unavailabilities,
      this.all.filter((agenda: CGenAgendaChauffeurRow) => {
        const typeId =
          typeof agenda.ACH_TYE_ID === 'object' ? agenda.ACH_TYE_ID.TYE_ID : agenda.ACH_TYE_ID;
        return typeId !== CGenTypeRegleAgendaRow.TYE_AVAILABLE;
      }),
    );
  }

  get listeValide() {
    if (this._listeValide === undefined) {
      this.build_listeValide();
    }
    return this._listeValide;
  }

  build_listeValide() {
    if (this._listeValide === undefined) {
      this._listeValide = new Array<CGenAgendaChauffeurRow>();

      // on designe les champs qui doivent reconstruire cette liste
      this.bindfilter(['ACH_SAC_ID'], ['build_listeValide']);
    }
    this.arrayMerge(
      this._listeValide,
      this.all.filter((value: CGenAgendaChauffeurRow) => {
        return value.ACH_SAC_ID === '2';
      }),
    );
  }

  get calendar(): Subject<Array<CalendarEvent>> {
    if (this._calendar === undefined) {
      this.build_calendar();
    }
    return this._calendar;
  }

  build_calendar() {
    if (this._calendar === undefined) {
      this._calendar = new Subject();
    }

    const liste = [];
    this.listeValide.forEach((ach: CGenAgendaChauffeurRow) => {
      const event: CalendarEvent<CGenAgendaChauffeurRow> = ach;
      liste.push(event);
    });
    this._calendar.next(liste);
  }

  isDriverAvailable(time: Date): boolean {
    const isAvailable      = this.availableAt(time)
    const isNotUnavailable = !this.unavailableAt(time);
    return isAvailable && isNotUnavailable;
  }

  availableAt(time: Date): boolean {
    return this.isTimeMatching(time, this.availabilities);
  }

  unavailableAt(time: Date): boolean {
    return this.isTimeMatching(time, this.unavailabilities);
  }

  isTimeMatching(time: Date, agendaItems: CGenAgendaChauffeurRow[]): boolean {
    const day         = time.getDay();
    const hour        = time.getHours().toString().padStart(2, '0');
    const minute      = time.getMinutes().toString().padStart(2, '0');
    const second      = time.getSeconds().toString().padStart(2, '0');
    const currentTime = `${hour}:${minute}:${second}`;

    const matchingItem = agendaItems.find(agenda => {
      const matchingDay       = agenda[`ACH_J${day}`] === DayActive.ACTIVE;
      const matchingStartTime = agenda.ACH_HEURE_DEBUT <= currentTime;
      const matchingEndTime   = agenda.ACH_HEURE_FIN >= currentTime;
      return matchingDay && matchingStartTime && matchingEndTime;
    });

    return !!matchingItem;
  }

  isDaySelected(selectedDays: Day[], targetDay: Day): DayActive {
    return selectedDays.includes(targetDay) ? DayActive.ACTIVE : DayActive.INACTIVE;
  }

  isNightShiftBatch(slotsBatch: Slot[]): boolean {
    const eveningSlot   = slotsBatch.some((slot: Slot) => slot.shift === Shift.EVENING);
    const nightSlot     = slotsBatch.some((slot: Slot) => slot.shift === Shift.NIGHT);
    const afternoonSlot = slotsBatch.some((slot: Slot) => slot.shift === Shift.AFTERNOON);
    return !afternoonSlot && eveningSlot && nightSlot;
  }

  getShiftStartTime(shift: Shift): string {
    return SHIFT_TIMES[shift].start;
  }

  getShiftEndTime(shift: Shift): string {
    return SHIFT_TIMES[shift].end;
  }

  getNextShift(currentShift: Shift): Shift {
    switch (currentShift) {
      case Shift.NIGHT:
        return Shift.MORNING;
      case Shift.MORNING:
        return Shift.AFTERNOON;
      case Shift.AFTERNOON:
        return Shift.EVENING;
      case Shift.EVENING:
        return Shift.NIGHT;
      default:
        return Shift.NIGHT;
    }
  }

  getShiftPosition(shift: Shift): number {
    switch (shift) {
      case Shift.NIGHT:
        return 0;
      case Shift.MORNING:
        return 1;
      case Shift.AFTERNOON:
        return 2;
      case Shift.EVENING:
        return 3;
      default:
        return 0;
    }
  }

  getNextDay(day: Day): Day {
    switch (day) {
      case Day.SUNDAY:
        return Day.MONDAY;
      case Day.MONDAY:
        return Day.TUESDAY;
      case Day.TUESDAY:
        return Day.WEDNESDAY;
      case Day.WEDNESDAY:
        return Day.THURSDAY;
      case Day.THURSDAY:
        return Day.FRIDAY;
      case Day.FRIDAY:
        return Day.SATURDAY;
      case Day.SATURDAY:
        return Day.SUNDAY;
      default:
        throw new Error("Invalid day");
    }
  }

  getUpdatedDaySlotsFromHours(
    startTime: string,
    endTime: string,
    daySlots: DayScheduleSlots,
  ): { night: boolean; morning: boolean; afternoon: boolean; evening: boolean } {
    const startShift = this.getShiftFromHour(startTime, true);
    const endShift   = this.getShiftFromHour(endTime, false);
    if (startShift === endShift) {
      daySlots[startShift] = true;
    }

    if (this.getShiftPosition(startShift) > this.getShiftPosition(endShift)) {
      daySlots[Shift.NIGHT]   = true;
      daySlots[Shift.EVENING] = true;
      if (endShift === Shift.MORNING) {
        daySlots[Shift.MORNING] = true;
      }
      return daySlots;
    }

    for (let i = this.getShiftPosition(startShift); i <= this.getShiftPosition(endShift); i++) {
      const shift = Object.values(Shift)[i];
      if (!daySlots[shift]) {
        daySlots[shift] = true;
      }
    }
    return daySlots;
  }

  getShiftFromHour(hour: string, isStartingTime: boolean): Shift {
    switch (true) {
      case hour === '00:00:00':
        return Shift.NIGHT;
      case hour === '06:00:00':
        return isStartingTime ? Shift.MORNING : Shift.NIGHT;
      case hour === '12:00:00':
        return isStartingTime ? Shift.AFTERNOON : Shift.MORNING;
      case hour === '18:00:00':
        return isStartingTime ? Shift.EVENING : Shift.AFTERNOON;
      default:
        return Shift.EVENING;
    }
  }
}

export {
  Day,
  Shift,
  DayActive,
  DaySchedule,
  DayScheduleSlots,
  Slot,
  SHIFT_TIMES,
  DEFAULT_RECURRING,
  DEFAULT_REPEAT,
};
