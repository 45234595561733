import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsMarque extends JafConcept {
  public primary = 'MAR_ID';

  protected name = 'nf_gds_marque';

  protected class = 'C_Gds_Marque';

  protected rowClass = 'CGdsMarqueRow';

  protected trigramme = 'MAR';

  public _flagUniqueRowset = true;
}