import { JafRow } from '@way-lib-jaf/row';
import { CGenMissionRow, CComModeReglementRow, CGenChauffeurRow } from '@way-lib-jaf/rowLoader';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CComReglementRow extends JafRow {
  @WayChamp({
    label: '%LAB_COM_REGLEMENT_ID%',
    class: 'Id',
  })
  public REL_ID;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_LIBELLE%',
    class: 'Libelle',
  })
  public REL_LIBELLE;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_FAC_ID%',
    class: 'Liaison',
  })
  public REL_FAC_ID;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_DATE_ECHEANCE%',
    class: 'Date',
  })
  public REL_DATE_ECHEANCE;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_MONTANT_ECHEANCE%',
    class: 'Montant',
  })
  public REL_MONTANT_ECHEANCE;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_FLAG_RECEPTION_CHAUFFEUR%',
    class: 'Flag',
  })
  public REL_FLAG_RECEPTION_CHAUFFEUR;

  @WayChamp({
    label  : '%LAB_COM_REGLEMENT_CHU_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Chauffeur',
  })
  public REL_CHU_ID: CGenChauffeurRow;

  @WayChamp({
    label  : '%LAB_COM_REGLEMENT_MIS_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Mission',
    indexed: true,
  })
  public REL_MIS_ID: CGenMissionRow;

  @WayChamp({
    label  : '%LAB_COM_REGLEMENT_MRE_ID%',
    class  : 'Liaison',
    liaison: 'C_Com_ModeReglement',
  })
  public REL_MRE_ID: CComModeReglementRow;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_DATE_REGLEMENT%',
    class: 'Date',
  })
  public REL_DATE_REGLEMENT;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_MONTANT_REGLER%',
    class: 'Montant',
  })
  public REL_MONTANT_REGLER;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_PIECE_COMPTABLE%',
    class: 'Texte',
  })
  public REL_PIECE_COMPTABLE;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_COMMENTAIRE%',
    class: 'Textarea',
  })
  public REL_COMMENTAIRE;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_FLAG_EXPORT%',
    class: 'Flag',
  })
  public REL_FLAG_EXPORT;

  @WayChamp({
    label: '%LAB_COM_REGLEMENT_FLAG_EN_CAISSE%',
    class: 'Flag',
  })
  public REL_FLAG_EN_CAISSE;

  @WayChamp({
    label  : '%LAB_COM_FACTURE_TITRE_CONCEPT%',
    class  : 'Liaison',
    liaison: 'C_Com_Facture',
  })
  public REL_AVOIR_FAC_ID;
}
