import { JafConcept } from '@way-lib-jaf/concept';

export class CGenPassager extends JafConcept {
  public primary = 'PAS_ID';

  protected name = 'nf_gen_passager';

  protected class = 'C_Gen_Passager';

  protected rowClass = 'CGenPassagerRow';

  protected trigramme = 'PAS';
}
