import { JafConcept } from '@way-lib-jaf/concept';
import { CGdsTypeVehiculeRow } from './CGdsTypeVehiculeRow';

export class CGdsTypeVehicule extends JafConcept {
  public primary = 'TVH_ID';

  protected name = 'nf_gds_typeVehicule';

  protected class = 'C_Gds_TypeVehicule';

  protected rowClass = 'CGdsTypeVehiculeRow';

  protected trigramme = 'TVH';

  static ACCEPTED_VEHICLE_TYPES_TVH_IDS = [
    CGdsTypeVehiculeRow.TVH_BUS,
    CGdsTypeVehiculeRow.TVH_MOTO, 
    CGdsTypeVehiculeRow.TVH_SEDAN_BERLINE,
    CGdsTypeVehiculeRow.TVH_VAN,
    CGdsTypeVehiculeRow.TVH_LIMOUSINE,
    CGdsTypeVehiculeRow.TVH_MINIBUS,
    CGdsTypeVehiculeRow.TVH_MONOSPACE,
    CGdsTypeVehiculeRow.TVH_BREAK,
    CGdsTypeVehiculeRow.TVH_COUPE,
    CGdsTypeVehiculeRow.TVH_CABRIOLET,
    CGdsTypeVehiculeRow.TVH_COMPACTE,
    CGdsTypeVehiculeRow.TVH_4X4,
    CGdsTypeVehiculeRow.TVH_CITADINE,
    CGdsTypeVehiculeRow.TVH_SUV
 ];

  get vehicleTypes(): CGdsTypeVehiculeRow[] {
    return <CGdsTypeVehiculeRow[]>(
      this.all.filter((type: CGdsTypeVehiculeRow) =>
        CGdsTypeVehicule.ACCEPTED_VEHICLE_TYPES_TVH_IDS.includes(type.TVH_ID),
      )
    );
  }
}
