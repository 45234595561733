import { Pipe, PipeTransform } from '@angular/core';
import { CGenTypeRegleAgendaRow } from '@way-lib-jaf/rowLoader';
import { WayMode } from '../services/way-mode.service';

@Pipe({ name: 'typeRegleAgendaFlagWayd' })
export class TypeRegleAgendaFlagWaydPipe implements PipeTransform {
  transform(items: CGenTypeRegleAgendaRow[], wayMode?: WayMode): CGenTypeRegleAgendaRow[] {
    return items?.filter((item: CGenTypeRegleAgendaRow) => {
      if (
        wayMode === WayMode.WAY_PARTNER &&
        item.TYE_ID !== CGenTypeRegleAgendaRow.TYE_UNAVAILABLE
      ) {
        return false;
      }
      return item.TYE_FLAG_WAYD === '1';
    });
  }
}