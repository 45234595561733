import { JafConcept } from '@way-lib-jaf/concept';

export class CGenEtapePresence extends JafConcept {
  public primary = 'EPR_ID';

  protected name = 'nf_gen_etapePresence';

  protected class = 'C_Gen_EtapePresence';

  protected rowClass = 'CGenEtapePresenceRow';

  protected trigramme = 'EPR';

  builds = ['build_etapes'];
}
