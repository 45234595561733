import { JafConcept } from '@way-lib-jaf/concept';

export class CGenChauffeur extends JafConcept {
  public primary = 'CHU_ID';

  protected name = 'nf_gen_chauffeur';

  protected class = 'C_Gen_Chauffeur';

  protected rowClass = 'CGenChauffeurRow';

  protected trigramme = 'CHU';

  getInternalDriver() {
    const WayPartnerId     = this.cm.getInstallationPrincipal();
    const [internalDriver] = this.getRowsetByIndex('CHU_PAR_ID', WayPartnerId.INS_COMPTE, String(WayPartnerId.INS_ID * 1e11));
    return internalDriver;
  }
}
