import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CComModeReglementRow extends JafRow {
  @WayChamp({
    label: '%LAB_COM_MODEREGLEMENT_ID%',
    class: 'Id',
  })
  public MRE_ID;

  @WayChamp({
    label: '%LAB_COM_MODEREGLEMENT_LIBELLE%',
    class: 'Libelle',
  })
  public MRE_LIBELLE;

  @WayChamp({
    label: '%LAB_COM_MODEREGLEMENT_CODE_COMPTA%',
    class: 'Texte',
  })
  public MRE_CODE_COMPTA;

  @WayChamp({
    label: '%LAB_COM_MODEREGLEMENT_CODE_JOURNAL%',
    class: 'Texte',
  })
  public MRE_CODE_JOURNAL;

  @WayChamp({
    label: '%LAB_COM_MODEREGLEMENT_COMPTA_LIBELLE%',
    class: 'Texte',
  })
  public MRE_COMPTA_LIBELLE;
}
