import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absolute',
})
export class AbsolutePipe implements PipeTransform {
  transform(value: number | string): number {
    let tmpValue = value;
    if (typeof tmpValue === 'string') {
      tmpValue = parseInt(tmpValue, 10);
    }
    return Math.abs(tmpValue);
  }
}
