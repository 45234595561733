import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Storage } from '@ionic/storage-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Chauffeur } from '@way-lib/common/interfaces/chauffeur';
import { Gds } from '@way-lib-jaf/gds';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { AuthService } from '@way-lib/common/services/auth/auth.service';
import { Jaf } from '@way-lib-jaf/jaf';
import { MenuService } from '../../../../services/menu.service';

interface ChauffeurCompteResponse {
  action?: string;
  params: {
    chauffeur: Chauffeur;
  };
}

export const ConfirmPassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password                = control.get('password');
  const confirmation            = control.get('confirmation');
  const error: ValidationErrors = { passwordNotEquals: true };

  return password && confirmation && password.value !== confirmation.value ? error : null;
};

@Component({
  selector   : 'app-profile-form',
  styleUrls  : ['./profile-form.component.scss'],
  templateUrl: './profile-form.component.html',
})
export class ProfileFormComponent implements OnInit {

  Jaf = Jaf;

  suForm = new UntypedFormGroup(
    {
      login       : new UntypedFormControl('', [Validators.required, Validators.email]),
      password    : new UntypedFormControl('', [Validators.required]),
      confirmation: new UntypedFormControl('', [Validators.required]),
      language    : new UntypedFormControl('', [Validators.required]),
      title       : new UntypedFormControl('', [Validators.required]),
      name        : new UntypedFormControl('', [Validators.required]),
      firstName   : new UntypedFormControl('', [Validators.required]),
      tel         : new UntypedFormControl('', [Validators.required]),
      /*  tel2: new FormControl('', []),
    address: new FormControl('', []),
    zipCode: new FormControl('', []),
    city: new FormControl('', []),
    birthDate: new FormControl('', []),
    birthZipCode: new FormControl('', []),
    birthCity: new FormControl('', []),
    birthCountry: new FormControl('', []),
    nationality: new FormControl('', []),
    foreignLanguages: new FormControl('', []),
    healthInsuranceNumber: new FormControl('', []),
    lastMedicalVisit: new FormControl('', []),
    driverLicense: new FormControl('', []),
    driverLicenseDate: new FormControl('', []),
    idCard: new FormControl('', []),
    idCardDate: new FormControl('', []),
    idPro: new FormControl('', []),
    idProDate: new FormControl('', []),
    visa: new FormControl('', []),
    visaDate: new FormControl('', []),

  */
    },
    { validators: ConfirmPassword },
  );

  submitting = false;

  sliderOptions: object = {
    allowTouchMove: false,
  };

  currentSlide = 0;

  public insertMode = false;

  public chauffeur:Chauffeur;

  public demandeSuppressionFlag = false;

  @ViewChild(IonContent, { read: IonContent }) content: IonContent;

  public signupError: string = '';

  constructor(
    private http: Gds,
    private router: Router,
    private storage: Storage,
    private authService: AuthService,
    public menuService: MenuService,
    private activatedroute: ActivatedRoute,
    private translate: TranslateService,
    private cm: ConceptManager,
  ) {}

  segmentChanged($event: CustomEvent) {
    this.currentSlide = +$event.detail.value;
    // return this.slider.slideTo($event.detail.value);
  }

  // slideDidChange() {
  //   this.slider.getActiveIndex().then(index => {
  //     this.segment.value = index.toString();
  //   });
  // }

  ngOnInit() {
    this.storage.get('wayd.chauffeur').then((chauffeur) => {
      if (!chauffeur) {
        this.insertMode = true;
        return;
      }
      this.chauffeur = chauffeur;
      this.suForm.patchValue({
        login       : chauffeur.CHU_EMAIL,
        password    : chauffeur.CHU_MDP,
        confirmation: chauffeur.CHU_MDP,
        language    : chauffeur.CHU_LAN_ID.toString(),
        title       : chauffeur.CHU_CIV_ID.toString(),
        name        : chauffeur.CHU_NOM,
        firstName   : chauffeur.CHU_PRENOM,
        tel         : chauffeur.CHU_TEL_MOBILE_1,
        /*
        tel2: chauffeur.CHU_TEL_MOBILE_2,
        address: chauffeur.CHU_ADRESSE,
        zipCode: chauffeur.CHU_CODE_POSTAL,
        city: chauffeur.CHU_VILLE,
        birthDate: chauffeur.CHU_DATE_NAISSANCE,
        birthZipCode: chauffeur.CHU_CODE_POSTAL_NAISSANCE,
        birthCity: chauffeur.CHU_VILLE_NAISSANCE,
        birthCountry: chauffeur.CHU_PAY_NAISSANCE,
        nationality: chauffeur.CHU_NATIONALITE,
        foreignLanguages: chauffeur.CHU_LANGUE_PARLE,
        healthInsuranceNumber: chauffeur.CHU_NUMERO_SECU,
        lastMedicalVisit: chauffeur.CHU_DATE_VISITE_MEDICALE,
        driverLicense: chauffeur.CHU_NUMERO_PERMIS,
        driverLicenseDate: chauffeur.CHU_DATE_PERMIS,
        idCard: chauffeur.CHU_NUMERO_CARTE_ID,
        idCardDate: chauffeur.CHU_DATE_CARTE_ID,
        idPro: chauffeur.CHU_NUMERO_CARTE_PRO,
        idProDate: chauffeur.CHU_DATE_CARTE_PRO,
        visa: chauffeur.CHU_CARTE_SEJOUR,
        visaDate: chauffeur.CHU_DATE_CARTE_SEJOUR,

 */
      });
    });
  }

  demandeSuppression() {
    if (this.chauffeur && this.chauffeur.CHU_ID) {
      this.http
        .post('divers', '/gdsv3/demande-suppression-chauffeur', {CHU_ID: this.chauffeur.CHU_ID})
        .subscribe(() => {
          this.signupError            = `DIC_DEMANDE_ANNULATION`;
          this.demandeSuppressionFlag = true;
          this.cm.detectChanges();
        },()=>{
          this.signupError = `La plate forme semble indisponible. Veuillez ressayer dans quelques minutes. Puis contacter support@waynium.com`;
          this.cm.detectChanges();
        });
    }
    this.cm.detectChanges();
  }


  onSubmit() {
    if (!this.suForm.valid) {
      return;
    }
    this.submitting = true;
    this.http
      .post('divers', '/gdsv3/chauffeur-compte', {
        CHU_NOM         : this.suForm.value.name,
        CHU_PRENOM      : this.suForm.value.firstName,
        CHU_EMAIL       : this.suForm.value.login,
        CHU_MDP         : this.suForm.value.password,
        CHU_LAN_ID      : this.suForm.value.language.toString(),
        CHU_CIV_ID      : this.suForm.value.title,
        CHU_TEL_MOBILE_1: this.suForm.value.tel,
        /*        CHU_TEL_MOBILE_2: this.suForm.value.tel2,
        CHU_ADRESSE: this.suForm.value.address,
        CHU_CODE_POSTAL: this.suForm.value.zipCode,
        CHU_VILLE: this.suForm.value.city,
        CHU_DATE_NAISSANCE: this.suForm.value.birthDate,
        CHU_CODE_POSTAL_NAISSANCE: this.suForm.value.birthZipCode,
        CHU_VILLE_NAISSANCE: this.suForm.value.birthCity,
        CHU_PAY_NAISSANCE: this.suForm.value.birthCountry,
        CHU_NATIONALITE: this.suForm.value.nationality,
        CHU_LANGUE_PARLE: this.suForm.value.foreignLanguages,
        CHU_NUMERO_SECU: this.suForm.value.healthInsuranceNumber,
        CHU_DATE_VISITE_MEDICALE: this.suForm.value.lastMedicalVisit,
        CHU_NUMERO_PERMIS: this.suForm.value.driverLicense,
        CHU_DATE_PERMIS: this.suForm.value.driverLicenseDate,
        CHU_NUMERO_CARTE_ID: this.suForm.value.idCard,
        CHU_DATE_CARTE_ID: this.suForm.value.idCardDate,
        CHU_NUMERO_CARTE_PRO: this.suForm.value.idPro,
        CHU_DATE_CARTE_PRO: this.suForm.value.idProDate,
        CHU_CARTE_SEJOUR: this.suForm.value.visa,
        CHU_DATE_CARTE_SEJOUR: this.suForm.value.visaDate,

 */
      })
      .subscribe((res: ChauffeurCompteResponse) => {
        if (undefined !== res.params.chauffeur) {
          this.authService.login({
            login   : this.suForm.value.login,
            password: this.suForm.value.password,
          });
        } else {
          this.signupError = `Ce compte existe déjà merci d'utiliser le formulaire de mot de passe oublié`;
        }
      },()=>{
        this.signupError = `La plate forme semble indisponible. Veuillez ressayer dans quelques minutes. Puis contacter support@waynium.com`;
        this.submitting  = false;
        this.cm.detectChanges();
        setTimeout(()=>{
          this.signupError = ``;
          this.cm.detectChanges();
        },5000);
      });
    this.submitting = false;
    this.cm.detectChanges();
  }

  showErrors(control: string) {
    const { dirty, touched, errors } = this.suForm.get(control);
    return dirty && touched && errors;
  }
}
