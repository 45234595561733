import { JafRow } from '@way-lib-jaf/row';
import { CGdsPartenaireCercleRow , CGdsInstallationRow } from '@way-lib-jaf/rowLoader';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { WayChampBuild } from '@way-lib/common/decorator/WayChampBuild';

export class CGdsCercleRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_CERCLE_ID%',
    class: 'Id',
  })
  public CER_ID;

  @WayChamp({
    label: '%LAB_GDS_LABEL%',
    class: 'Libelle',
  })
  public CER_LIBELLE;

  @WayChamp({
    label: '%LAB_GDS_DESCRIPTION%',
    class: 'Textarea',
  })
  public CER_DESCRIPTION;

  @WayChamp({
    label: '%LAB_GDS_INSTALLATION_INS_ID%',
    class: 'Liaison',
  })
  public CER_INS_ID;

  @WayChampBuild('Index', {
    conceptName: 'C_Gds_PartenaireCercle',
    indexName  : 'PAC_CER_ID',
  })
  public pacs: Array<CGdsPartenaireCercleRow>;

  @WayChampBuild('Array')
  public partners: Array<CGdsInstallationRow> = [];

  // @WayBuild(['CER_ID'])
  build_partners() {
    this.partners = new Array<CGdsInstallationRow>();
    this.pacs.forEach((pac) => {
      this.partners.push(pac.PAC_INS_ID);
    });
  }
}