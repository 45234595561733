import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProfilePage } from './profile.page';

const routes: Routes = [
  {
    path     : '',
    component: ProfilePage,
    children : [
      {
        path        : 'profile-form',
        loadChildren: () =>
          import('./profile-form/profile-form.module').then((m) => m.ProfileFormModule),
      },
      {
        path      : '',
        redirectTo: 'profile-form',
        pathMatch : 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfilePageRoutingModule {}
