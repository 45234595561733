import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: number | string, ...args: string[]): number | string {
    const [country, format] = args;
    const phoneUtil         = PhoneNumberUtil.getInstance();
    try {
      const parsedInput = phoneUtil.parse(value.toString(), country);
      switch (format) {
        case 'E164':
          return phoneUtil.format(parsedInput, PhoneNumberFormat.E164);
          break;
        case 'NATIONAL':
          return phoneUtil.format(parsedInput, PhoneNumberFormat.NATIONAL);
          break;
        default:
          return phoneUtil.format(parsedInput, PhoneNumberFormat.INTERNATIONAL);
          break;
      }
    } catch (e) {
      /**
       * Si on ne trouve pas de le pays:
       * ni en paramètre
       * ni en préfix du numéro (+XX)
       */
      return value;
    }
  }
}
