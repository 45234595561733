import { Component, Input } from '@angular/core';

@Component({
  selector   : 'way-error',
  templateUrl: './way-error.component.html',
  styleUrls  : ['./way-error.component.scss'],
})
export class WayErrorComponent {
  @Input() error = '';
}
